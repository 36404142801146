import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';

import styles from './index.module.scss';

interface ISwitch {
  onChange?: () => void;
  isBlue?: boolean;
  checked: boolean;
  isLoading?: boolean;
}

const Switcher: React.FunctionComponent<ISwitch> = ({
  onChange,
  isBlue,
  checked,
  isLoading,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const moduleClasses = classNames.bind(styles);
  const switcherClasses = moduleClasses('switcher', {
    'switcher-dark': !isLightMode && !isBlue,
    'switcher-blue': isBlue,
  });
  const handleClasses = moduleClasses('switcher__handle', {
    'switcher__handle-dark': !isLightMode && !isBlue,
  });
  const switchClasses = moduleClasses('switcher__switch', {
    'switcher__switch-checked': checked,
  });

  return (
    <label htmlFor="theme" className={styles.wrapper}>
      <span className={switcherClasses}>
        <input
          type="checkbox"
          defaultChecked={checked}
          onChange={onChange}
          className={styles.switcher__input}
          id="theme"
          disabled={isLoading}
        />
        <span className={switchClasses}>
          <span className={handleClasses} />
        </span>
      </span>
    </label>
  );
};

export default Switcher;
