import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import routes from 'constants/routes';
import { INPUT_TYPES } from 'constants/constants';

import {
  TError,
  TSignInUserDataWithEmail,
  IFormData,
  IInputData,
} from 'types/models';

import { validationSchemaSignIn } from 'helpers/validation';

import useEmailSignIn from 'hooks/api/useEmailSignIn';

import AuthContainer from 'components/common/AuthContainer';
import Form from 'components/common/Form';

import MailSVG from 'assets/icons/mail';
import LockSVG from 'assets/icons/lock';

import styles from './index.module.scss';

const fieldsParameters: Array<IInputData> = [
  {
    name: 'email',
    type: INPUT_TYPES.EMAIL,
    required: true,
    placeholder: 'Email',
    Icon: MailSVG,
  },
  {
    name: 'password',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'Password',
    Icon: LockSVG,
  },
];

const signUpLink = (
  <Link
    to={{
      pathname: routes.SIGN_UP,
      state: { fromAuth: true },
    }}
    className={styles.signup}
  >
    Sign up
  </Link>
);
const forgotPasswordLink = (
  <Link
    to={{
      pathname: routes.RESET_PASSWORD,
      state: { fromAuth: true },
    }}
    className={styles.link}
  >
    Forgot password?
  </Link>
);

const SignIn: React.FunctionComponent = () => {
  const [socialError, setSocialError] = useState<TError>();

  const { mutate, isLoading, error } = useEmailSignIn();

  const onSubmit = (data: IFormData) => {
    const { email, password } = data;

    if (email && password) {
      const userData = { email, password } as TSignInUserDataWithEmail;
      mutate(userData);
    }
  };

  return (
    <AuthContainer
      isSocial
      sign="Don’t have an account?"
      link={signUpLink}
      setSocialError={setSocialError}
    >
      <Form
        onSubmit={onSubmit}
        fields={fieldsParameters}
        title="Sign In"
        btnTitle="enter account"
        sign={forgotPasswordLink}
        validationSchema={validationSchemaSignIn}
        disableModeSwitch
        submitError={error || socialError}
        loading={isLoading}
      />
    </AuthContainer>
  );
};

export default SignIn;
