import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames/bind';

import routes from 'constants/routes';
import Logo from 'components/common/Logo';
import SocialSignIn from 'components/common/SocialSignIn';
import { TError } from 'types/models';
import history from 'helpers/history';

import ArrowBackSVG from 'assets/icons/arrow-back';

import photosCollageImg from 'assets/images/auth-photos-collage.png';

import styles from './index.module.scss';

interface IAuthContainer {
  children: JSX.Element;
  isSocial?: boolean;
  link?: JSX.Element;
  sign?: string;
  setSocialError?: (error: TError) => void;
}

interface ILocationState {
  fromAuth: boolean;
}

const AuthContainer: React.FunctionComponent<IAuthContainer> = ({
  children,
  isSocial,
  sign,
  link,
  setSocialError,
}) => {
  const {
    goBack,
    location: { state },
  } = useHistory<ILocationState>();
  const isResetPasswordPage = useRouteMatch(routes.RESET_PASSWORD);

  const isFromAuth = state && state.fromAuth;
  const isBackButtonVisible = isFromAuth || isResetPasswordPage;

  const goBackHandler = () => {
    const isHistoryState = !!state;

    if (!isHistoryState && isResetPasswordPage) {
      history.push(routes.SIGN_IN, { fromAuth: true });

      return;
    }

    goBack();
  };

  const moduleClasses = classNames.bind(styles);
  const formClasses = moduleClasses('form', {
    'form-centered': isResetPasswordPage,
  });

  return (
    <main className={styles.container}>
      <section className={styles.design}>
        <Logo />
        <img
          alt="collage"
          src={photosCollageImg}
          className={styles.design__photos}
        />
      </section>

      <section className={formClasses}>
        <div className={styles.form__wrapper}>
          {isBackButtonVisible && (
            <button
              type="button"
              className={styles.back}
              onClick={goBackHandler}
            >
              <ArrowBackSVG isDark />
            </button>
          )}

          {children}

          {isSocial && setSocialError && (
            <SocialSignIn setSocialError={setSocialError} />
          )}

          {link && (
            <p className={styles.sign}>
              {sign}
              &nbsp;
              {link}
            </p>
          )}
        </div>
      </section>
    </main>
  );
};

export default AuthContainer;
