import { useContext } from 'react';
import { useMutation } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';

import { IAlbumResponse, TError, TNewPhoto } from 'types/models';

import { submitAlbum } from 'services/api/albums';

import {
  MAX_LENGTH_ERROR,
  UPLOADED_PHOTOS_MAX_LENGTH,
} from 'constants/constants';

interface ISubmitAlbum {
  submit: (albumLength: number, uploadedPhotos?: TNewPhoto[]) => void;
  isLoading: boolean;
  isSuccess: boolean;
}

const EMPTY_LIKES_ERROR =
  'You did not like any photos. This helps machine learning know your tastes';

export default (): ISubmitAlbum => {
  const {
    dispatch,
    state: {
      user: { token },
    },
    uploadAlbum: { data },
    dispatchUploadAlbum,
  } = useContext(AppContext);

  const { mutate, isLoading, isSuccess } = useMutation<
    IAlbumResponse,
    TError,
    number
  >((desiredSize: number) =>
    submitAlbum(desiredSize, dispatch, dispatchUploadAlbum, token, data?.key)
  );

  const submit = (albumLength: number, uploadedPhotos?: TNewPhoto[]) => {
    if (albumLength === 0) {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
        payload: 'You did not specify desired album length.',
      });

      return;
    }
    const isPhotosLiked = uploadedPhotos?.some((photo) => photo.liked);

    const uploadedPhotosLength = uploadedPhotos?.length;
    const isPhotosLengthLargerMax =
      uploadedPhotosLength && uploadedPhotosLength > UPLOADED_PHOTOS_MAX_LENGTH;

    const isError = !isPhotosLiked || isPhotosLengthLargerMax;

    if (isError) {
      if (!uploadedPhotosLength) return;

      const errorMessage = isPhotosLengthLargerMax
        ? MAX_LENGTH_ERROR
        : EMPTY_LIKES_ERROR;

      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
        payload: errorMessage,
      });
    } else if (uploadedPhotos) {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.RESET_ERROR,
      });
      mutate(albumLength);
    }
  };

  return { submit, isLoading, isSuccess };
};
