import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import PublicRoutes from 'components/App/routes/PublicRoutes';
import PrivateRoutes from 'components/App/routes/PrivateRoutes';

import 'assets/styles/index.module.scss';

const App: React.FunctionComponent = () => {
  const {
    state: {
      user: { id },
    },
  } = useContext(AppContext);

  const isUserLoggedIn = !!id;

  return isUserLoggedIn ? <PrivateRoutes /> : <PublicRoutes />;
};

export default App;
