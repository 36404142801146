import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';

import useGetAlbum from 'hooks/api/useGetAlbum';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';

import routes from 'constants/routes';
import { DEFAULT_TITLE } from 'constants/constants';

import Logo from 'components/common/Logo';
import Spinner from 'components/common/Spinner';

import photosCollageImg from 'assets/images/invite-photos-collage.png';

import styles from './index.module.scss';

interface IPublicParams {
  hash: string;
}

const Invite: React.FunctionComponent = () => {
  const { hash } = useParams<IPublicParams>();
  const {
    state: {
      user: { id },
    },
    dispatch,
  } = useContext(AppContext);

  const { data: album, isLoading } = useGetAlbum({
    key: hash,
    isPublic: true,
  });

  const isUserLoggedIn = !!id;

  const onClick = () => {
    if (!isUserLoggedIn) {
      dispatch({
        type: ACTION_TYPES.SET_USER_INVITE_REDIRECT,
        payload: hash,
      });
    }
  };

  const { title, user_name: author } = album || {};
  const linkPath = isUserLoggedIn
    ? `${routes.PUBLIC_VIEW}/${hash}`
    : routes.SIGN_IN;

  return (
    <div className={styles.wrapper}>
      <Logo />

      {isLoading && (
        <div className={styles.spinner}>
          <Spinner size={100} width={10} disableModeSwitch />
        </div>
      )}

      {!isLoading && (
        <>
          <img
            src={photosCollageImg}
            alt="photos collage"
            className={styles.collage}
          />
          <h2 className={styles.invite}>You are invited to view</h2>
          {title && <h1 className={styles.title}>{title || DEFAULT_TITLE}</h1>}
          {author && <h3 className={styles.author}>by {author}</h3>}

          <Link to={linkPath} className={styles.link} onClick={onClick}>
            <span>view album</span>
          </Link>
        </>
      )}
    </div>
  );
};

export default Invite;
