/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import classNames from 'classnames/bind';

import { IInputData } from 'types/models';
import { INPUT_TYPES, INPUT_NAMES } from 'constants/constants';
import { AppContext } from 'context/AppProvider';
import AvatarField from 'components/common/Avatar';

import visibleImg from 'assets/images/visible-on.svg';
import hiddenImg from 'assets/images/visible-off.svg';

import styles from './index.module.scss';

interface IInputField {
  data: IInputData;
  register: UseFormRegister<FieldValues>;
  disableModeSwitch?: boolean;
  disableChanges?: boolean;
}

const InputField: React.FunctionComponent<IInputField> = ({
  data,
  register,
  disableModeSwitch,
  disableChanges,
}) => {
  const [focused, setFocused] = useState(false);
  const [isHidden, setHide] = useState(true);
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const {
    type,
    name: fieldName,
    placeholder,
    Icon,
    required,
    isPasswordEyeHidden,
  } = data;

  const inputRegister = register(fieldName, { required });

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    inputRegister.onBlur(e);
    setFocused(false);
  };

  const switchPasswordVisibility = () => {
    setHide(!isHidden);
  };

  const isPassword = type === INPUT_TYPES.PASSWORD;
  const isPasswordVisible = isPassword && !isHidden;
  const isAvatarField = fieldName === INPUT_NAMES.AVATAR;
  const isPasswordEyeVisible = isPassword && !isPasswordEyeHidden;

  const moduleClasses = classNames.bind(styles);
  const wrapperClasses = moduleClasses('wrapper', {
    'wrapper-avatar': isAvatarField,
  });
  const inputClasses = moduleClasses('input', {
    'input-dark': !isLightMode && !disableModeSwitch,
    'input-password': isPassword,
  });

  return (
    <fieldset className={wrapperClasses}>
      {isAvatarField ? (
        <AvatarField register={inputRegister} disableChanges={disableChanges} />
      ) : (
        <input
          type={isPasswordVisible ? INPUT_TYPES.TEXT : type}
          placeholder={placeholder}
          onFocus={onFocus}
          className={inputClasses}
          autoComplete={isPassword ? 'off' : 'on'}
          {...inputRegister}
          onBlur={onBlur}
          disabled={disableChanges}
        />
      )}

      <div className={styles.icon}>
        {Icon && (
          <Icon
            isDark={focused}
            isInput
            disableModeSwitch={disableModeSwitch}
          />
        )}
      </div>

      {isPasswordEyeVisible && (
        <button
          type="button"
          className={styles.eye}
          onClick={switchPasswordVisibility}
        >
          <img src={isHidden ? hiddenImg : visibleImg} alt="eye" />
        </button>
      )}
    </fieldset>
  );
};

export default InputField;
