import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import useCheckDraftAlbum from 'hooks/api/useCheckDraftAlbum';

import { AppContext } from 'context/AppProvider';

import { IAlbumResponse } from 'types/models';

import Header from 'components/common/Header';
import AlbumsList from 'components/common/AlbumsList';
import Container from 'components/common/Container';
import Button from 'components/common/Button';
import MainContainer from 'components/common/MainContainer';
import Spinner from 'components/common/Spinner';

import plusSVG from 'assets/icons/plus';

import styles from './index.module.scss';

interface IAlbumsFull {
  profileLink: JSX.Element;
  themeSwitcher: JSX.Element;
  albums?: Array<IAlbumResponse>;
}

const AlbumsFull: React.FunctionComponent<IAlbumsFull> = ({
  profileLink,
  themeSwitcher,
  albums,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const { setEnabled: setCheckDraftEnabled, isLoading } =
    useCheckDraftAlbum(true);

  const openModal = () => {
    setCheckDraftEnabled(true);
  };

  const moduleClasses = classNames.bind(styles);
  const plusButtonStyles = moduleClasses('header__button', {
    'header__button-dark': !isLightMode,
  });

  const headerRightComponent = (
    <div className={styles.header}>
      {isLoading ? (
        <Spinner size={40} width={6} />
      ) : (
        <Button
          onClick={openModal}
          title="Create album"
          IconComponent={plusSVG}
          additionalClasses={plusButtonStyles}
        />
      )}
      {profileLink}
    </div>
  );

  return (
    <MainContainer style={styles.wrapper}>
      <Header
        leftButton={themeSwitcher}
        heading="Albums"
        rightButton={headerRightComponent}
        fixedOnScroll={1}
        isHeadingUppercase
      />

      <main className={styles.main}>
        <Container className={styles.container}>
          {albums && <AlbumsList albums={albums} />}
        </Container>
      </main>
    </MainContainer>
  );
};

export default AlbumsFull;
