import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-query';

import routes from 'constants/routes';
import { QUERY_KEYS, CHECK_STATUS_CONDITIONS } from 'constants/constants';

import history from 'helpers/history';

import { checkDraftAlbum, deleteAlbum } from 'services/api/albums';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { ACTION_TYPES } from 'context/modules/main/actions';

import ModalContent from 'components/common/ModalContent';

import { TError } from 'types/models';

interface ICheckDraft {
  setEnabled: (enabled: boolean) => void;
  isLoading: boolean;
}

export default (openChoosePhotosModal?: boolean): ICheckDraft => {
  const [isEnabled, setEnabled] = useState<boolean>(false);
  const {
    state: {
      user: { token },
    },
    uploadAlbum: { data: album },
    dispatch,
    dispatchUploadAlbum,
  } = useContext(AppContext);

  const { mutate: cancelAlbum, isLoading } = useMutation<void, TError, string>(
    (key) => deleteAlbum(dispatch, key, token),
    {
      onSuccess: () => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
        });
      },

      onSettled: () => {
        dispatch({
          type: ACTION_TYPES.SET_MODAL_PARAMETERS,
          payload: {
            visible: false,
            isChoosePhotos: false,
            content: null,
          },
        });

        history.push(routes.MAIN);
      },
    }
  );

  const { data, isLoading: loading } = useQuery(
    QUERY_KEYS.DRAFT_ALBUM,
    () => checkDraftAlbum(dispatch, token),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isEnabled && !album?.key,
      onSuccess: (response) => {
        if (response && !album?.key) {
          const continueCreation = () => {
            dispatchUploadAlbum({
              type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
              payload: response,
            });
            dispatchUploadAlbum({
              type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED,
              payload: CHECK_STATUS_CONDITIONS.ML_PROCESSED,
            });

            dispatch({
              type: ACTION_TYPES.SET_MODAL_PARAMETERS,
              payload: { visible: false, isChoosePhotos: false, content: null },
            });

            history.push(routes.CREATE_ALBUM_PHOTOS);
          };

          const onDelete = () => {
            if (response.key) cancelAlbum(response.key);
          };

          dispatch({
            type: ACTION_TYPES.SET_MODAL_PARAMETERS,
            payload: {
              visible: true,
              isChoosePhotos: false,
              content: (
                <ModalContent
                  title="Draft album"
                  subtitle={`You have one draft album ${
                    data?.title ? `"${data?.title}"` : ''
                  }`}
                  message="Do you want to continue making this album?"
                  rightButtonOptions={{
                    text: 'Continue',
                    onClick: continueCreation,
                  }}
                  leftButtonOptions={{
                    text: 'Delete',
                    onClick: onDelete,
                  }}
                  isLoading={isLoading}
                />
              ),
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPES.SET_MODAL_PARAMETERS,
            payload: {
              visible: !!openChoosePhotosModal,
              isChoosePhotos: openChoosePhotosModal,
              content: null,
            },
          });
        }
      },
      onSettled: (response) => {
        const isChoosePhotos = !response?.key && openChoosePhotosModal;

        if (isChoosePhotos) {
          dispatch({
            type: ACTION_TYPES.SET_MODAL_PARAMETERS,
            payload: {
              visible: true,
              isChoosePhotos: true,
            },
          });
        }
        setEnabled(false);
      },
    }
  );

  return { setEnabled, isLoading: loading };
};
