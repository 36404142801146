import { useContext } from 'react';
import {
  useInfiniteQuery,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  InfiniteData,
  UseInfiniteQueryOptions,
} from 'react-query';

import { AppContext } from 'context/AppProvider';
import { getAlbumImages } from 'services/api/images';
import { TImagesPage } from 'types/models';

interface IGetImagesProps {
  key?: string;
  options?: UseInfiniteQueryOptions<TImagesPage>;
  isUploadAlbum?: boolean;
  isPublic?: boolean;
}

interface IGetImages {
  data?: InfiniteData<TImagesPage>;
  hasNextPage?: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<TImagesPage, unknown>>;
}

export default ({ key, options, isPublic }: IGetImagesProps): IGetImages => {
  const {
    state: {
      user: { token },
    },
    dispatch,
  } = useContext(AppContext);

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['album images', key],
    ({ pageParam }) =>
      getAlbumImages({
        dispatch,
        albumKey: key,
        token,
        nextPageUrl: pageParam,
        isPublic,
      }),
    {
      getNextPageParam: (page) => page.next,
      staleTime: Infinity,
      ...options,
    }
  );

  return { data, hasNextPage, fetchNextPage };
};
