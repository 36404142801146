import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { resetPassword } from 'services/firebaseApi/auth';
import { INPUT_TYPES } from 'constants/constants';
import { validationSchemaResetPassword } from 'helpers/validation';
import { IFormData, TError, IInputData } from 'types/models';
import AuthContainer from 'components/common/AuthContainer';
import Form from 'components/common/Form';

import MailSVG from 'assets/icons/mail';

import styles from './index.module.scss';

const fieldsParameters: Array<IInputData> = [
  {
    name: 'email',
    type: INPUT_TYPES.EMAIL,
    required: true,
    placeholder: 'Email',
    Icon: MailSVG,
  },
];

const ResetPassword: React.FunctionComponent = () => {
  const [success, setSuccess] = useState(false);

  const { mutate, isLoading, error } = useMutation<void, TError, string>(
    (email) => resetPassword(email),
    {
      onSuccess: () => {
        setSuccess(true);
      },
    }
  );

  const onSubmit = (data: IFormData) => {
    const { email } = data;
    if (email) {
      mutate(email);
    }
  };

  return (
    <AuthContainer>
      <>
        {success && (
          <p className={styles.success}>
            Please check your email to confirm password reset.
          </p>
        )}
        <Form
          onSubmit={onSubmit}
          fields={fieldsParameters}
          title="Reset password"
          btnTitle="Submit"
          validationSchema={validationSchemaResetPassword}
          disableModeSwitch
          loading={isLoading}
          submitError={error}
          cleanAfterSubmit
        />
      </>
    </AuthContainer>
  );
};

export default ResetPassword;
