import { IAlbumResponse } from 'types/models';

import { createAlbum } from 'services/api/albums';
import { createExternalImage } from 'services/api/images';

import { TActions } from 'context/modules/main/actions';
import {
  TUploadAlbumActions,
  UPLOAD_ALBUM_ACTION_TYPES,
} from 'context/modules/uploadAlbum/actions';

import getFileExtension from 'helpers/getFileExtension';
import sliceArrayIntoChunks from 'helpers/sliceArrayIntoChunks';

import { CHECK_STATUS_CONDITIONS } from 'constants/constants';

declare const Dropbox: Dropbox;

interface Dropbox {
  choose(options: DropboxChooseOptions): void;
}

interface DropboxChooseOptions {
  success(files: DropboxFile[]): void;
  linkType: 'direct' | 'preview';
  multiselect: boolean;
  extensions: string[];
  folderselect: boolean;
}

export interface DropboxFile {
  id: string;
  link: string;
  thumbnailLink: string;
  name: string;
}

const DROPBOX_LOAD_URL = 'https://www.dropbox.com/static/api/2/dropins.js';

export const loadDropbox = (): void => {
  const id = 'dropboxjs';

  const isScriptExists = document.getElementById(id);

  if (isScriptExists) return;

  const dropboxScript = document.createElement('script');
  dropboxScript.src = DROPBOX_LOAD_URL;
  dropboxScript.id = id;
  dropboxScript.dataset.appKey = process.env.REACT_APP_DROPBOX_KEY;

  document.body.appendChild(dropboxScript);
};

export const dropboxUpload = (
  onGetFilesSuccess: (files: DropboxFile[]) => boolean,
  dispatch: React.Dispatch<TActions>,
  token: string,
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>,
  album?: IAlbumResponse
): void => {
  const options: DropboxChooseOptions = {
    success: async (files) => {
      const isLargerMaxLength = onGetFilesSuccess(files);

      if (isLargerMaxLength) return;

      const currentAlbum = await createAlbum(
        files.length,
        dispatch,
        dispatchAlbum,
        token,
        album
      );

      if (currentAlbum.key) {
        const chunks = sliceArrayIntoChunks(files);

        dispatchAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED,
          payload: CHECK_STATUS_CONDITIONS.ML_PROCESSED,
        });

        for (let i = 0; i < chunks.length; i += 1) {
          const promises = [];

          for (let j = 0; j < chunks[i].length; j += 1) {
            const { link, name } = chunks[i][j];

            const fileExtension = getFileExtension(name, true);

            const promise = createExternalImage(
              fileExtension,
              link,
              token,
              dispatch,
              currentAlbum
            );

            promises.push(promise);
          }

          // eslint-disable-next-line no-await-in-loop
          await Promise.all(promises);
        }
      }
    },
    linkType: 'direct',
    multiselect: true,
    folderselect: false,
    extensions: ['images'],
  };

  Dropbox.choose(options);
};
