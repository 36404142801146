import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';
import Spinner from 'components/common/Spinner';

import styles from './index.module.scss';

const LoadScreen: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const moduleClasses = classNames.bind(styles);
  const wrapperClasses = moduleClasses('wrapper', {
    'wrapper-dark': !isLightMode,
  });

  return (
    <div className={wrapperClasses}>
      <Spinner size={100} width={10} />
    </div>
  );
};

export default LoadScreen;
