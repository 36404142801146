import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  TSignUpUserDataWithEmail,
  TError,
  IFormData,
  IInputData,
} from 'types/models';

import { INPUT_TYPES } from 'constants/constants';
import routes from 'constants/routes';

import { validationSchemaSignUp } from 'helpers/validation';

import useEmailSignUp from 'hooks/api/useEmailSignUp';

import AuthContainer from 'components/common/AuthContainer';
import Form from 'components/common/Form';

import ProfileSVG from 'assets/icons/profile';
import MailSVG from 'assets/icons/mail';
import LockSVG from 'assets/icons/lock';

const fieldsParameters: Array<IInputData> = [
  {
    name: 'username',
    type: INPUT_TYPES.TEXT,
    required: true,
    placeholder: 'Username',
    Icon: ProfileSVG,
  },
  {
    name: 'email',
    type: INPUT_TYPES.EMAIL,
    required: true,
    placeholder: 'Email',
    Icon: MailSVG,
  },
  {
    name: 'password',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'Password',
    Icon: LockSVG,
  },
  {
    name: 'confirmPassword',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'Confirm password',
    Icon: LockSVG,
  },
];

const signInLink = (
  <Link
    to={{
      pathname: routes.SIGN_IN,
      state: { fromAuth: true },
    }}
  >
    Sign in
  </Link>
);

const SignUp: React.FunctionComponent = () => {
  const [socialError, setSocialError] = useState<TError>();

  const { mutate, isLoading, error } = useEmailSignUp();

  const onSubmit = (data: IFormData) => {
    const { email, password, username } = data;

    if (email && password && username) {
      const userData = {
        email,
        password,
        username,
      } as TSignUpUserDataWithEmail;

      mutate(userData);
    }
  };

  return (
    <AuthContainer
      isSocial
      link={signInLink}
      sign="Already have an account?"
      setSocialError={setSocialError}
    >
      <Form
        onSubmit={onSubmit}
        fields={fieldsParameters}
        title="Sign Up"
        btnTitle="create account"
        validationSchema={validationSchemaSignUp}
        disableModeSwitch
        submitError={error || socialError}
        loading={isLoading}
      />
    </AuthContainer>
  );
};

export default SignUp;
