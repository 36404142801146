import { INewAlbumState } from 'context/AppProvider';
import {
  TUploadAlbumActions,
  UPLOAD_ALBUM_ACTION_TYPES,
} from 'context/modules/uploadAlbum/actions';
import { TNewPhoto } from 'types/models';

export default (
  state: INewAlbumState,
  action: TUploadAlbumActions
): INewAlbumState => {
  switch (action.type) {
    case UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS: {
      const newPhotos = [
        ...(state.data?.images || []),
        ...(action.payload?.images || []),
      ];

      return {
        ...state,
        data: { ...state.data, ...action.payload, images: newPhotos },
        loading: false,
        error: '',
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_PHOTO_LIKE: {
      if (state.data) {
        const { images: uploadedPhotos } = state.data;

        if (uploadedPhotos) {
          const newPhotos = [...uploadedPhotos];
          const photoIndex = newPhotos?.findIndex(
            (photo) => photo.key === action.payload
          );
          const currentPhoto = newPhotos[photoIndex];

          const { liked } = currentPhoto;
          newPhotos[photoIndex] = { ...currentPhoto, liked: !liked };

          return {
            ...state,
            data: { ...state.data, images: newPhotos },
          };
        }
      }
      return state;
    }
    case UPLOAD_ALBUM_ACTION_TYPES.DELETE_UPLOADED_PHOTO: {
      if (state.data) {
        const { images, cover_picture: mainPhoto } = state.data;

        if (images) {
          const newPhotos = images?.filter(
            (photo: TNewPhoto) => photo.key !== action.payload
          );
          const size = state.data?.size && state.data.size - 1;

          const newData = {
            ...state.data,
            size,
            images: newPhotos,
          };

          const isMainPhoto = action.payload === mainPhoto?.key;

          if (isMainPhoto) {
            const firstPhoto = newPhotos[0];
            newData.cover_picture = firstPhoto;
          }

          return { ...state, loading: false, data: newData };
        }
      }

      return state;
    }
    case UPLOAD_ALBUM_ACTION_TYPES.RESET_ERROR: {
      return { ...state, loading: false, error: '' };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_PHOTOS: {
      const images = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          images,
        },
        error: '',
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM: {
      return {
        loading: false,
        error: '',
        data: {},
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_ALBUM_INSTANCE: {
      return {
        ...state,
        data: {
          ...action.payload,
          images: state.data?.images || [],
        },
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED: {
      return {
        ...state,
        isCheckStatusEnabled: action.payload,
        loading: !!action.payload,
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_PHOTOS_PAGE: {
      const newPhotos = [...(state.data?.images || []), ...action.payload];

      return {
        ...state,
        data: {
          ...state.data,
          images: newPhotos,
        },
      };
    }
    case UPLOAD_ALBUM_ACTION_TYPES.SET_ALBUM_SIZE: {
      return {
        ...state,
        data: {
          ...state.data,
          size: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
