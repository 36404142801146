import {
  UPLOADED_PHOTOS_MAX_LENGTH,
  CHECK_STATUS_CONDITIONS,
} from 'constants/constants';

import { IAlbumResponse, TNewPhoto } from 'types/models';

import {
  TUploadAlbumActions,
  UPLOAD_ALBUM_ACTION_TYPES,
} from 'context/modules/uploadAlbum/actions';
import { TActions } from 'context/modules/main/actions';

import getFileExtension from 'helpers/getFileExtension';

import { createAlbum } from 'services/api/albums';
import { createGoogleExternalImage } from 'services/api/images';
import sliceArrayIntoChunks from 'helpers/sliceArrayIntoChunks';

const API_KEY = process.env.REACT_APP_PICKER_KEY;
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GAPI_LOAD_URL = 'https://apis.google.com/js/api.js';
const GOOGLE_DRIVE_SCORE = 'https://www.googleapis.com/auth/drive.file ';

declare global {
  interface Window {
    onGapiLoad: () => void;
  }
}

let isPickerApiLoaded = false;

export const loadGapi = (): void => {
  window.onGapiLoad = function onGapiLoad() {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: CLIENT_ID,
        scope: GOOGLE_DRIVE_SCORE,
      });
    });

    gapi.load('picker', () => {
      isPickerApiLoaded = true;
    });
  };

  const id = 'google-js';

  const isScriptExists = document.getElementById(id);

  if (isScriptExists) return;

  const gapiScript = document.createElement('script');
  gapiScript.src = GAPI_LOAD_URL;
  gapiScript.id = id;
  gapiScript.onload = window.onGapiLoad;

  document.body.appendChild(gapiScript);
};

const saveFile = async (
  doc: google.picker.DocumentObject,
  token: string,
  apiToken: string,
  dispatch: React.Dispatch<TActions>,
  albumKey?: string
): Promise<TNewPhoto> => {
  try {
    const { id, mimeType } = doc;
    const extension = getFileExtension(mimeType);

    if (albumKey) {
      const imageData = {
        album: albumKey,
        google_image_id: id,
        token,
        extension,
        liked: false,
      };

      const image = await createGoogleExternalImage(
        imageData,
        dispatch,
        apiToken
      );

      return image;
    }

    throw Error('[save file]: album key is missing');
  } catch (error) {
    throw Error(error.message);
  }
};

async function pickerCallback(
  data: google.picker.ResponseObject,
  redirect: () => void,
  authResponse: gapi.auth2.AuthResponse,
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>,
  dispatch: React.Dispatch<TActions>,
  apiToken: string,
  album?: IAlbumResponse
) {
  const isFilesPicked =
    data[google.picker.Response.ACTION] === google.picker.Action.PICKED;

  if (isFilesPicked) {
    redirect();
  }

  if (data.docs) {
    const { docs } = data;

    const currentAlbum = await createAlbum(
      docs.length,
      dispatch,
      dispatchAlbum,
      apiToken,
      album
    );

    if (currentAlbum.key) {
      const chunks = sliceArrayIntoChunks(docs);

      dispatchAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED,
        payload: CHECK_STATUS_CONDITIONS.ML_PROCESSED,
      });

      for (let i = 0; i < chunks.length; i += 1) {
        const promises = [];

        for (let j = 0; j < chunks[i].length; j += 1) {
          const promise = saveFile(
            chunks[i][j],
            authResponse.access_token,
            apiToken,
            dispatch,
            currentAlbum.key
          );

          promises.push(promise);
        }

        // eslint-disable-next-line no-await-in-loop
        await Promise.all(promises);
      }
    }
  }
}

function createPicker(
  authResponse: gapi.auth2.AuthResponse,
  redirect: () => void,
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>,
  dispatch: React.Dispatch<TActions>,
  token: string,
  album?: IAlbumResponse
) {
  if (isPickerApiLoaded && CLIENT_ID && API_KEY) {
    const { access_token: accessToken } = authResponse;
    const view = new google.picker.DocsView();

    const maxItems = album?.size
      ? UPLOADED_PHOTOS_MAX_LENGTH - album.size
      : UPLOADED_PHOTOS_MAX_LENGTH;

    const picker = new google.picker.PickerBuilder()
      .setAppId(CLIENT_ID)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .addView(google.picker.ViewId.DOCS_IMAGES)
      .addView(view)
      .setOAuthToken(accessToken)
      .setDeveloperKey(API_KEY)
      .setCallback((data) =>
        pickerCallback(
          data,
          redirect,
          authResponse,
          dispatchAlbum,
          dispatch,
          token,
          album
        )
      )
      .setMaxItems(maxItems)
      .setTitle('Select images')
      .setSize(500, 900)
      .build();

    picker.setVisible(true);
  }
}

export async function googleUpload(
  redirect: () => void,
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>,
  dispatch: React.Dispatch<TActions>,
  token: string,
  album?: IAlbumResponse
): Promise<void> {
  const googleAuth: gapi.auth2.GoogleAuth = gapi.auth2.getAuthInstance();

  const isSignedIn = googleAuth.isSignedIn.get();

  if (isSignedIn) {
    await googleAuth.grantOfflineAccess({
      prompt: 'select_account',
      scope: GOOGLE_DRIVE_SCORE,
    });

    const googleUser: gapi.auth2.GoogleUser = googleAuth.currentUser.get();
    const authResponse = googleUser.getAuthResponse();

    createPicker(authResponse, redirect, dispatchAlbum, dispatch, token, album);
  } else {
    const googleUser = await googleAuth.signIn();
    const isUserSignedIn = googleUser.isSignedIn();

    if (isUserSignedIn) {
      const authResponse = googleUser.getAuthResponse();

      createPicker(
        authResponse,
        redirect,
        dispatchAlbum,
        dispatch,
        token,
        album
      );
    }
  }
}
