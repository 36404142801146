import React, { useContext, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { InfiniteData } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';

import routes from 'constants/routes';

import { AppContext } from 'context/AppProvider';

import {
  IAlbumResponse,
  IInfiniteQueryOptions,
  TImagesPage,
} from 'types/models';

import Header from 'components/common/Header';
import AlbumMenu from 'components/common/AlbumMenu';
import AlbumInfo from 'components/AlbumInfo';
import PhotosList from 'components/common/PhotosList';
import Container from 'components/common/Container';
import Spinner from 'components/common/Spinner';

import ArrowBackSVG from 'assets/icons/arrow-back';
import ArrowDownSVG from 'assets/icons/arrow-down';

import styles from './index.module.scss';

interface IAlbumView {
  album?: IAlbumResponse;
  images?: InfiniteData<TImagesPage>;
  isLoading?: boolean;
  infiniteQueryOptions: IInfiniteQueryOptions;
  isPublic?: boolean;
}

const SCROLL_DELAY = 300;

const AlbumView: React.FunctionComponent<IAlbumView> = ({
  album,
  images,
  isLoading,
  infiniteQueryOptions,
  isPublic,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);
  const photosRef = useRef<HTMLUListElement>(null);

  const scrollToPhotosList = () => {
    setTimeout(() => {
      photosRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, SCROLL_DELAY);
  };

  const headerRightButton = isPublic ? undefined : <AlbumMenu album={album} />;
  const headerRightComponent = !isLoading ? headerRightButton : undefined;

  const moduleClasses = classNames.bind(styles);
  const wrapperClasses = moduleClasses('wrapper', {
    'wrapper-dark': !isLightMode,
  });
  const linkClasses = moduleClasses('link', {
    'link-dark': !isLightMode,
  });

  const backLink = (
    <Link to={routes.MAIN} className={linkClasses}>
      <ArrowBackSVG />
    </Link>
  );

  const dataLength = useMemo(() => {
    const count = images?.pages.reduce((acc, page) => {
      const newAcc = acc + page.results.length;
      return newAcc;
    }, 0);

    return count || 0;
  }, [images?.pages.length]);

  const { hasNextPage, fetchNextPage } = infiniteQueryOptions;
  const { title, key } = album || {};

  const linkPath = `${isPublic ? routes.PUBLIC_VIEW : routes.ALBUM}/${key}`;

  return (
    <div className={wrapperClasses}>
      <Header
        fixedOnScroll={714}
        leftButton={backLink}
        heading={title || ''}
        rightButton={headerRightComponent}
      />

      <main>
        <AlbumInfo album={album} loading={isLoading} />

        {images && (
          <>
            <Link
              to={{ pathname: linkPath, hash: '#photo' }}
              className={styles.down}
              onClick={scrollToPhotosList}
            >
              <ArrowDownSVG />
            </Link>

            <InfiniteScroll
              dataLength={dataLength}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={
                <div className={styles.spinner}>
                  <Spinner size={50} />
                </div>
              }
            >
              <Container className={styles.container}>
                <PhotosList
                  data={images}
                  ref={photosRef}
                  infiniteQueryOptions={{
                    fetchNextPage,
                    hasNextPage,
                  }}
                />
              </Container>
            </InfiniteScroll>
          </>
        )}
      </main>
    </div>
  );
};

export default AlbumView;
