/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import ReactSlider from 'react-slider';
import classNames from 'classnames/bind';

import { UPLOADED_PHOTOS_MAX_LENGTH } from 'constants/constants';
import { AppContext } from 'context/AppProvider';

import styles from './index.module.scss';

interface IAlbumLengthHandler {
  albumLength: number;
  setAlbumLength: (value: number) => void;
  maxValue?: number;
  classes?: string;
}

const AlbumLengthHandler: React.FunctionComponent<IAlbumLengthHandler> = ({
  albumLength,
  setAlbumLength,
  classes,
  maxValue,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const onChange = (value: number) => {
    setAlbumLength(value);
  };

  const moduleClasses = classNames.bind(styles);
  const sliderClasses = moduleClasses('slider', {
    'slider-dark': !isLightMode,
  });
  const thumbClasses = moduleClasses('thumb', {
    'thumb-dark': !isLightMode,
  });
  const titleClasses = moduleClasses('title', {
    'title-dark': !isLightMode,
  });

  return (
    <div className={`${classes} ${styles.wrapper}`}>
      <h3 className={titleClasses}>Album length</h3>

      <ReactSlider
        className={sliderClasses}
        min={0}
        max={maxValue || UPLOADED_PHOTOS_MAX_LENGTH}
        value={albumLength}
        onChange={onChange}
        trackClassName={styles.track}
        renderThumb={(props, state) => (
          <div {...props} className={thumbClasses}>
            <span>{state.valueNow}</span>
          </div>
        )}
      />
    </div>
  );
};

export default AlbumLengthHandler;
