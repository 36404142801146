import axios from 'axios';
import { isNetworkOrIdempotentRequestError } from 'axios-retry';

import {
  IAlbumResponse,
  TImagesPage,
  TNewPhoto,
  IGoogleExternalImage,
} from 'types/models';

import { API_URL, URLS, CHECK_STATUS_CONDITIONS } from 'constants/constants';
import routes from 'constants/routes';

import { createAlbum, updateAlbum } from 'services/api/albums';

import getFileExtension from 'helpers/getFileExtension';
import {
  authorizedPutRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedDeleteRequest,
} from 'helpers/axios';
import sliceArrayIntoChunks from 'helpers/sliceArrayIntoChunks';
import history from 'helpers/history';

import {
  TUploadAlbumActions,
  UPLOAD_ALBUM_ACTION_TYPES,
} from 'context/modules/uploadAlbum/actions';
import { TActions } from 'context/modules/main/actions';

interface IUploadFileSystemPhotos {
  files: FileList;
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>;
  album?: IAlbumResponse;
  token?: string;
  dispatch: React.Dispatch<TActions>;
}

export const createImage = async (
  extension: string,
  album: IAlbumResponse,
  file: File,
  dispatch: React.Dispatch<TActions>,
  token?: string
): Promise<void> => {
  try {
    // eslint-disable-next-line camelcase
    const data = await authorizedPostRequest<{ upload_link: string }>({
      token,
      dispatch,
      path: `${URLS.ALBUMS}${URLS.IMAGE}${URLS.ADD}/`,
      body: {
        extension,
        album: album.key,
      },
      retryCondition: (e) =>
        isNetworkOrIdempotentRequestError(e) || e.response?.status !== 201,
    });
    const { upload_link: uploadLink } = data;

    await axios.put(uploadLink, file, {
      headers: { 'Content-Type': file.type },
    });
  } catch {
    if (album.size) {
      await updateAlbum(dispatch, album.key, { size: album.size - 1 }, token);
    }
  }
};

const uploadImagesFromFileSystem = async (
  dispatch: React.Dispatch<TActions>,
  files: FileList,
  album: IAlbumResponse,
  token?: string
): Promise<void> => {
  try {
    if (album) {
      const filesArray = Array.from(files);
      const chunks = sliceArrayIntoChunks(filesArray);

      for (let i = 0; i < chunks.length; i += 1) {
        const promises = [];

        for (let j = 0; j < chunks[i].length; j += 1) {
          const file = chunks[i][j];
          const extension = getFileExtension(file.type);

          const promise = createImage(extension, album, file, dispatch, token);

          promises.push(promise);
        }

        // eslint-disable-next-line no-await-in-loop
        await Promise.allSettled(promises);
      }
    } else {
      history.push(routes.MAIN);

      throw Error('[upload images from file system]: album is missing');
    }
  } catch (error) {
    throw Error(error);
  }
};

export const savePhotosFromFileSystem = async ({
  files,
  token,
  album,
  dispatchAlbum,
  dispatch,
}: IUploadFileSystemPhotos): Promise<void> => {
  try {
    const currentAlbum = await createAlbum(
      files.length,
      dispatch,
      dispatchAlbum,
      token,
      album
    );

    if (currentAlbum.key) {
      dispatchAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED,
        payload: CHECK_STATUS_CONDITIONS.ML_PROCESSED,
      });

      await uploadImagesFromFileSystem(dispatch, files, currentAlbum, token);
    }
  } catch (error) {
    throw Error(error.message);
  }
};

export const likeImage = async (
  dispatch: React.Dispatch<TActions>,
  liked: boolean,
  imageKey: string,
  token?: string
): Promise<{ liked: boolean }> => {
  try {
    const data = await authorizedPutRequest<{ liked: boolean }>({
      token,
      dispatch,
      path: `${URLS.ALBUMS}${URLS.IMAGE}/${imageKey}${URLS.UPDATE}/`,
      body: { liked },
    });

    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

export const deleteImage = async (
  dispatch: React.Dispatch<TActions>,
  dispatchUploadAlbum: React.Dispatch<TUploadAlbumActions>,
  imageKey: string,
  token?: string,
  album?: IAlbumResponse
): Promise<void | IAlbumResponse> => {
  try {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM,
    });

    await authorizedDeleteRequest({
      token,
      dispatch,
      path: `${URLS.ALBUMS}${URLS.IMAGE}/${imageKey}${URLS.DELETE}/`,
    });

    if (album && album.cover_picture) {
      const isCoverPicture = album.cover_picture.key === imageKey;

      if (isCoverPicture) {
        const newCoverPicture = album.images?.find(
          (image) => image.key !== imageKey
        );

        if (newCoverPicture) {
          const data = await updateAlbum(
            dispatch,
            album.key,
            { cover_picture: newCoverPicture },
            token
          );

          return data;
        }
      }
    }

    return undefined;
  } catch (error) {
    throw Error(error);
  }
};

export const getAlbumImages = async ({
  dispatch,
  albumKey,
  nextPageUrl,
  token,
  setPhotos,
  isPublic,
}: {
  dispatch: React.Dispatch<TActions>;
  albumKey?: string;
  nextPageUrl?: string;
  token?: string;
  setPhotos?: (photos: Array<TNewPhoto>) => void;
  isPublic?: boolean;
}): Promise<TImagesPage> => {
  try {
    if (albumKey) {
      const firstPageUrl = `${
        isPublic ? `${URLS.ALBUMS}${URLS.PUBLIC}` : `${URLS.ALBUMS}`
      }/${albumKey}/images/?page=1`;

      const url = nextPageUrl || firstPageUrl;

      const data = await authorizedGetRequest<TImagesPage>({
        token,
        dispatch,
        path: url,
        baseURL: nextPageUrl ? '' : API_URL,
      });

      if (setPhotos) {
        setPhotos(data.results);
      }
      return data;
    }

    history.push(routes.MAIN);
    throw Error('[get images]: album key os missing');
  } catch (error) {
    throw Error(error.message);
  }
};

export const createExternalImage = async (
  extension: string,
  link: string,
  token: string,
  dispatch: React.Dispatch<TActions>,
  album: IAlbumResponse
): Promise<TNewPhoto> => {
  try {
    if (album.key) {
      const body = { album: album.key, extension, liked: false, link };

      const { data } = await authorizedPostRequest({
        token,
        dispatch,
        path: `${URLS.ALBUMS}${URLS.IMAGE}${URLS.EXTERNAL}${URLS.ADD}/`,
        body,
        retryCondition: (e) =>
          isNetworkOrIdempotentRequestError(e) || e.response?.status !== 201,
      });

      return data;
    }

    throw Error('[create external image]: album key is missing');
  } catch (error) {
    if (album.size) {
      await updateAlbum(dispatch, album.key, { size: album.size - 1 }, token);
    }

    throw Error(error.message);
  }
};

export const checkAlbumImagesStatus = async ({
  dispatch,
  albumKey,
  token,
  nextPageUrl,
  setPhotos,
  isCheckStatusEnabled,
  isUploadCompleted,
}: {
  dispatch: React.Dispatch<TActions>;
  albumKey?: string;
  token?: string;
  nextPageUrl: string;
  setPhotos?: (photos: Array<TNewPhoto>) => void;
  isCheckStatusEnabled?: boolean | CHECK_STATUS_CONDITIONS;
  isUploadCompleted?: boolean;
}): Promise<TImagesPage> => {
  try {
    if (albumKey) {
      const data = await authorizedGetRequest<TImagesPage>({
        token,
        dispatch,
        path: `${URLS.ALBUMS}/${albumKey}/status/`,
      });

      const isCheckingProcessedStatus =
        isCheckStatusEnabled === CHECK_STATUS_CONDITIONS.PROCESSING;
      const isProcessing =
        isCheckingProcessedStatus && !data.is_selector_processed;

      const isCheckingMLReady =
        isCheckStatusEnabled === CHECK_STATUS_CONDITIONS.ML_PROCESSED;
      const isPhotosUploadCompleted =
        isUploadCompleted && isCheckingMLReady && !data.is_ml_processed;

      if (isProcessing || isPhotosUploadCompleted) {
        return data;
      }

      const images = await getAlbumImages({
        dispatch,
        albumKey,
        nextPageUrl: isCheckStatusEnabled ? undefined : nextPageUrl,
        setPhotos,
        token,
      });

      return { ...images, ...data };
    }

    throw Error('[get images upload status]: album key is missing');
  } catch (error) {
    throw Error(error.message);
  }
};

export const createGoogleExternalImage = async (
  imageData: IGoogleExternalImage,
  dispatch: React.Dispatch<TActions>,
  token: string
): Promise<TNewPhoto> => {
  try {
    const data = await authorizedPostRequest<TNewPhoto>({
      token,
      dispatch,
      path: `${URLS.ALBUMS}${URLS.IMAGE}${URLS.EXTERNAL}/google${URLS.ADD}/`,
      body: imageData,
      retryCondition: (e) =>
        isNetworkOrIdempotentRequestError(e) || e.response?.status !== 201,
    });

    return data;
  } catch (error) {
    throw Error(error.message);
  }
};
