import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';

import styles from './index.module.scss';

interface IMainContainer {
  children: React.ReactNode;
  style?: string;
}

const MainContainer: React.FunctionComponent<IMainContainer> = ({
  children,
  style,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const moduleClasses = classNames.bind(styles);
  const mainClasses = moduleClasses('main', {
    'main-dark': !isLightMode,
  });

  return <div className={`${mainClasses} ${style}`}>{children}</div>;
};

export default MainContainer;
