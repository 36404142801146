interface IFormatDate {
  month: string;
  year: string;
}

const getMonth = (month: number): string => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return '';
  }
};

const getDateInfo = (date: string): IFormatDate => {
  const dateObj = new Date(date);

  const month = dateObj.getMonth();
  const monthString = getMonth(month);
  const year = String(dateObj.getFullYear());

  return {
    month: monthString,
    year,
  };
};

export const formatDateFullYear = (date?: string): string => {
  if (date) {
    const options = getDateInfo(date);
    const { month, year } = options;

    return `${month} ${year}`;
  }
  return '';
};

export const formatDate = (date: string): string => {
  const options = getDateInfo(date);
  const { month, year } = options;

  const shortYear = `‘${year.slice(-2)}`;

  return `${month} ${shortYear}`;
};
