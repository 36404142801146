import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type Lazy from 'yup/lib/Lazy';

import InputField from 'components/common/InputField';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import Spinner from 'components/common/Spinner';

import { IFormData, TError, IInputData } from 'types/models';

import styles from './index.module.scss';

interface IForm {
  fields: Array<IInputData>;
  title?: string;
  btnTitle: string;
  sign?: JSX.Element;
  onSubmit: (data: IFormData) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: Lazy<any>;
  disableModeSwitch?: boolean;
  loading?: boolean;
  submitError?: TError | null;
  defaultValues?: IFormData;
  cleanAfterSubmit?: boolean;
  disableChanges?: boolean;
}

const Form: React.FunctionComponent<IForm> = ({
  fields,
  title,
  btnTitle,
  sign,
  onSubmit,
  validationSchema,
  disableModeSwitch,
  loading,
  submitError,
  defaultValues,
  cleanAfterSubmit,
  disableChanges,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const errorsKeys = Object.keys(errors) as Array<keyof IFormData>;

  const errorMessage = useMemo((): string => {
    const isErrors = !!errorsKeys.length;
    const firstError = errorsKeys[0];
    const error = isErrors && errors[firstError]?.message;

    return error || '';
  }, [errorsKeys]);

  const submit = (data: IFormData) => {
    onSubmit(data);

    if (cleanAfterSubmit) {
      reset();
    }
  };

  const isSpinner = loading && !disableChanges;
  const isButton = !loading && !disableChanges;

  return (
    <form className={styles.form} onSubmit={handleSubmit(submit)}>
      {title && <h1 className={styles.title}>{title}</h1>}

      {fields.map((field) => {
        const { name } = field;

        return (
          <InputField
            key={String(name)}
            data={field}
            register={register}
            disableModeSwitch={disableModeSwitch}
            disableChanges={disableChanges}
          />
        );
      })}

      <Error
        message={errorMessage}
        className={styles.error}
        disableModeSwitch={disableModeSwitch}
      />

      {sign}

      {isSpinner && <Spinner size={70} disableModeSwitch={disableModeSwitch} />}

      {isButton && (
        <Button
          title={btnTitle}
          isSubmit
          disableModeSwitch={disableModeSwitch}
          isBig
        />
      )}

      {submitError && (
        <Error
          message={submitError?.message}
          className={styles.error__submit}
          disableModeSwitch={disableModeSwitch}
        />
      )}
    </form>
  );
};

export default Form;
