enum routes {
  MAIN = '/',
  SIGN_UP = '/sign-up',
  SIGN_IN = '/sign-in',
  RESET_PASSWORD = '/reset-password',
  PROFILE = '/profile',
  ALBUM = '/album',
  CREATE_ALBUM_PHOTOS = '/new-album/photos',
  CREATE_ALBUM_SETTINGS = '/new-album/settings',
  EDIT_ALBUM_SETTINGS = '/edit-album/settings',
  EDIT_ALBUM_PHOTOS = '/edit-album/photos',
  INVITE = '/album/public',
  PUBLIC_VIEW = '/album/public/view',
}

export default routes;
