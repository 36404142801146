import { useContext } from 'react';
import { useMutation, UseMutateFunction } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';

import { TError, TSignInUserDataWithEmail } from 'types/models';

import history from 'helpers/history';

import routes from 'constants/routes';

import { signInWithEmail, getCurrentUser } from 'services/firebaseApi/auth';

interface ISignIn {
  mutate: UseMutateFunction<
    string | undefined,
    TError,
    TSignInUserDataWithEmail
  >;
  isLoading: boolean;
  error: TError | null;
}

export default (): ISignIn => {
  const {
    dispatch,
    state: {
      user: { isInviteRedirect },
    },
  } = useContext(AppContext);

  const { mutate, isLoading, error } = useMutation<
    string | undefined,
    TError,
    TSignInUserDataWithEmail
  >((userData) => signInWithEmail(userData), {
    onSuccess: (token) => {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const { uid, email, displayName, photoURL } = currentUser;

        const redirect = isInviteRedirect
          ? `${routes.PUBLIC_VIEW}/${isInviteRedirect}`
          : routes.MAIN;

        dispatch({
          type: ACTION_TYPES.SET_USER,
          payload: {
            id: uid,
            email,
            username: displayName,
            photoURL,
            token,
            isSocialSignIn: false,
          },
        });

        history.push(redirect);
      }
    },
  });

  return { mutate, isLoading, error };
};
