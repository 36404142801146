import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

interface IGalleryArrowSVG {
  reversed?: boolean;
}

const GalleryArrowSVG: React.FunctionComponent<IGalleryArrowSVG> = ({
  reversed,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const ellipseColor = isLightMode ? COLORS.BLACK : COLORS.WHITE;
  const color = isLightMode ? COLORS.WHITE : COLORS.BLACK;

  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <ellipse cx="25" cy="25" rx="25" ry="25" fill={ellipseColor} />
        <path
          d={
            reversed
              ? 'M33.7688 25.0001C33.7688 25.5377 33.5635 26.0753 33.1538 26.4853L20.2543 39.3846C19.4338 40.2051 18.1034 40.2051 17.2831 39.3846C16.4629 38.5643 16.4629 37.2342 17.2831 36.4136L28.6973 25.0001L17.2835 13.5865C16.4633 12.766 16.4633 11.436 17.2835 10.6158C18.1038 9.79482 19.4342 9.79482 20.2547 10.6158L33.1542 23.5149C33.564 23.925 33.7688 24.4626 33.7688 25.0001Z'
              : 'M16.45 25.0001C16.45 25.5377 16.6553 26.0753 17.065 26.4853L29.9644 39.3846C30.785 40.2051 32.1154 40.2051 32.9356 39.3846C33.7558 38.5643 33.7558 37.2342 32.9356 36.4136L21.5215 25.0001L32.9352 13.5865C33.7554 12.766 33.7554 11.436 32.9352 10.6158C32.115 9.79483 30.7846 9.79483 29.964 10.6158L17.0646 23.5149C16.6548 23.925 16.45 24.4626 16.45 25.0001Z'
          }
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GalleryArrowSVG;
