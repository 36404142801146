import { TNewPhoto, IAlbumResponse } from 'types/models';
import { CHECK_STATUS_CONDITIONS } from 'constants/constants';

export enum UPLOAD_ALBUM_ACTION_TYPES {
  SET_PHOTO_LIKE = 'SET_PHOTO_LIKE',
  DELETE_UPLOADED_PHOTO = 'DELETE_UPLOADED_PHOTO',
  SET_UPLOAD_ALBUM = 'SET_UPLOAD_ALBUM',
  SET_UPLOAD_ALBUM_ERROR = 'SET_UPLOAD_ALBUM_ERROR',
  SET_UPLOAD_ALBUM_SUCCESS = 'SET_UPLOAD_ALBUM_SUCCESS',
  CANCEL_UPLOAD_ALBUM = 'CANCEL_UPLOAD_ALBUM',
  RESET_ERROR = 'RESET_ERROR',
  SET_UPLOAD_ALBUM_PHOTOS = 'SET_UPLOAD_ALBUM_PHOTOS',
  SAVE = 'SAVE',
  SET_ALBUM_INSTANCE = 'SET_ALBUM_INSTANCE',
  SET_ALBUM_PHOTO = 'SET_ALBUM_PHOTO',
  SET_CHECK_STATUS_ENABLED = 'SET_CHECK_STATUS_ENABLED',
  SET_PHOTOS_PAGE = 'SET_PHOTOS_PAGE',
  SET_ALBUM_SIZE = 'SET_ALBUM_SIZE',
}

export interface ISetPhotoLike {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_PHOTO_LIKE;
  payload: string;
}

export interface IDeleteUploadedPhoto {
  type: UPLOAD_ALBUM_ACTION_TYPES.DELETE_UPLOADED_PHOTO;
  payload: string | ArrayBuffer | null;
}

export interface ISetNewAlbum {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM;
}

export interface ISetNewAlbumError {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR;
  payload: string;
}

export interface ISetNewAlbumSuccess {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS;
  payload?: IAlbumResponse;
}

export interface ISetNewAlbumPhotos {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_PHOTOS;
  payload: Array<TNewPhoto>;
}

export interface ICancelNewAlbum {
  type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM;
}

export interface IResetError {
  type: UPLOAD_ALBUM_ACTION_TYPES.RESET_ERROR;
}

export interface ISetAlbumInstance {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_ALBUM_INSTANCE;
  payload: IAlbumResponse;
}

export interface ISetCheckStatusEnabled {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED;
  payload: boolean | CHECK_STATUS_CONDITIONS;
}

export interface ISetPhotosPage {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_PHOTOS_PAGE;
  payload: TNewPhoto[];
}

export interface ISetAlbumSize {
  type: UPLOAD_ALBUM_ACTION_TYPES.SET_ALBUM_SIZE;
  payload: number;
}

export type TUploadAlbumActions =
  | ISetNewAlbum
  | ISetNewAlbumError
  | ISetNewAlbumSuccess
  | IDeleteUploadedPhoto
  | ISetPhotoLike
  | ICancelNewAlbum
  | IResetError
  | ISetNewAlbumPhotos
  | ISetAlbumInstance
  | ISetCheckStatusEnabled
  | ISetPhotosPage
  | ISetAlbumSize;
