import React from 'react';
import { useMutation } from 'react-query';

import { changePassword } from 'services/firebaseApi/auth';
import { INPUT_TYPES } from 'constants/constants';
import { validationSchemaChangePassword } from 'helpers/validation';
import Form from 'components/common/Form';
import Container from 'components/common/Container';
import {
  TChangePasswordData,
  TError,
  IUpdateComponentsProps,
  IFormData,
  IInputData,
} from 'types/models';

import LockSVG from 'assets/icons/lock';

import styles from './index.module.scss';

const fieldsParameters: Array<IInputData> = [
  {
    name: 'oldPassword',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'Current password',
    Icon: LockSVG,
    isPasswordEyeHidden: true,
  },
  {
    name: 'newPassword',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'New password',
    Icon: LockSVG,
  },
  {
    name: 'confirmPassword',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'Confirm password',
    Icon: LockSVG,
  },
];

const ChangePassword: React.FunctionComponent<IUpdateComponentsProps> = ({
  setSuccess,
}) => {
  const { mutate, isLoading, error } = useMutation<
    void,
    TError,
    TChangePasswordData
  >(
    ({ oldPassword, newPassword }) => changePassword(oldPassword, newPassword),
    {
      onSuccess: () => {
        setSuccess(true);
      },
    }
  );

  const onSubmit = (data: IFormData) => {
    const { oldPassword, newPassword } = data;

    if (oldPassword && newPassword) {
      mutate({ oldPassword, newPassword });
    }
  };

  return (
    <>
      <Container className={styles.container}>
        <Form
          onSubmit={onSubmit}
          fields={fieldsParameters}
          validationSchema={validationSchemaChangePassword}
          btnTitle="Apply Changes"
          loading={isLoading}
          submitError={error}
        />
      </Container>
    </>
  );
};

export default ChangePassword;
