import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

import styles from './index.module.scss';

interface ISpinner {
  size?: number;
  width?: number;
  disableModeSwitch?: boolean;
}

const Spinner: React.FunctionComponent<ISpinner> = ({
  size,
  width,
  disableModeSwitch,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = disableModeSwitch || isLightMode ? COLORS.BLACK : COLORS.WHITE;

  const wrapperStyles: React.CSSProperties = {
    width: size,
    height: size,
  };

  const ringStyle: React.CSSProperties = {
    width: size,
    height: size,
    borderWidth: width,
    borderColor: `${color} transparent transparent transparent`,
  };

  return (
    <div className={styles.spinner} style={wrapperStyles}>
      <div style={ringStyle} />
      <div style={ringStyle} />
      <div style={ringStyle} />
      <div style={ringStyle} />
    </div>
  );
};

export default Spinner;
