import React from 'react';
import { Link } from 'react-router-dom';

import routes from 'constants/routes';
import { formatDateFullYear } from 'helpers/formatDate';
import { IAlbumResponse } from 'types/models';

import InactiveAlbumSVG from 'assets/icons/album-inactive';
import photoTemplate from 'assets/images/photo-template.png';

import styles from './index.module.scss';

interface IAlbumsListProps {
  albums: IAlbumResponse[];
}

const AlbumsList: React.FunctionComponent<IAlbumsListProps> = ({ albums }) => (
  <ul className={styles.list}>
    {albums.map((album) => {
      const {
        key,
        title,
        created_at: date,
        cover_picture: mainPhoto,
        is_active: active,
        size,
      } = album;

      const linkPath = `${routes.ALBUM}/${key}`;

      const createdAt = formatDateFullYear(date);

      const { small_remote_url: smallUrl, remote_url: url } = mainPhoto || {};
      const coverUrl = smallUrl || url;

      return (
        <li key={key} className={styles.album}>
          <Link
            to={{ pathname: linkPath, state: { albumData: album } }}
            className={styles.link}
          >
            <figure className={styles.item}>
              {coverUrl ? (
                <img
                  src={coverUrl}
                  alt={title}
                  className={styles.item__img}
                  loading="lazy"
                />
              ) : (
                <div className={styles.item__template}>
                  <img
                    src={photoTemplate}
                    alt={title}
                    className={styles['item__img-template']}
                  />
                </div>
              )}

              {!active && (
                <span className={styles.item__inactive}>
                  <InactiveAlbumSVG />
                </span>
              )}

              <figcaption className={styles.item__description}>
                <p className={styles.item__count}>
                  {size || '0'}
                  &nbsp; photos
                </p>
                <p className={styles.item__title}>{title}</p>
                <p className={styles.item__date}>{createdAt}</p>
              </figcaption>
            </figure>
          </Link>
        </li>
      );
    })}
  </ul>
);

export default AlbumsList;
