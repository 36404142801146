import { useContext } from 'react';
import { useMutation, UseMutateFunction } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { savePhotosFromFileSystem } from 'services/api/images';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { TError } from 'types/models';

interface ISaveFilesFromFS {
  mutate: UseMutateFunction<void, TError, FileList>;
}

export default (): ISaveFilesFromFS => {
  const {
    dispatch,
    uploadAlbum: { data },
    dispatchUploadAlbum,
    state: {
      user: { token },
    },
  } = useContext(AppContext);

  const { mutate } = useMutation<void, TError, FileList>(
    (files) =>
      savePhotosFromFileSystem({
        files,
        token,
        album: data,
        dispatchAlbum: dispatchUploadAlbum,
        dispatch,
      }),
    {
      onError: (error) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
          payload: error.message,
        });
      },
    }
  );

  return { mutate };
};
