import { useContext } from 'react';
import { UseMutateFunction, useMutation } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { TError } from 'types/models';
import { likeImage } from 'services/api/images';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';

interface ILikePhoto {
  mutate: UseMutateFunction<
    {
      liked: boolean;
    },
    TError,
    boolean,
    unknown
  >;
  isLoading?: boolean;
}

export default (key: string): ILikePhoto => {
  const {
    dispatchUploadAlbum,
    dispatch,
    state: {
      user: { token },
    },
  } = useContext(AppContext);

  const { mutate, isLoading } = useMutation<
    { liked: boolean },
    TError,
    boolean
  >((isLiked) => likeImage(dispatch, isLiked, key, token), {
    onSuccess: () => {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_PHOTO_LIKE,
        payload: key,
      });
    },
    onError: (error) => {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
        payload: error.message,
      });
    },
  });

  return { mutate, isLoading };
};
