export enum INPUT_TYPES {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  FILE = 'file',
}

export enum COLORS {
  BLACK = '#000000',
  GREY = '#979191',
  WHITE = '#ffffff',
  GREY_1 = '#D9D2D2',
  GREY_2 = '#E4E4E4',
}

export enum INPUT_NAMES {
  AVATAR = 'avatar',
}

export const UPLOADED_PHOTOS_MAX_LENGTH = 100;
export const MAX_LENGTH_ERROR = `You can not choose more than ${UPLOADED_PHOTOS_MAX_LENGTH} photos for one album.`;

export enum LOCAL_STORAGE_KEYS {
  UI = 'ui',
  UPLOAD_ALBUM = 'newAlbum',
}

export const DEFAULT_TITLE = 'My album';

export const API_URL = process.env.REACT_APP_BACKEND_URL;

export enum URLS {
  ALBUMS = '/albums',
  LIST = '/list',
  IMAGE = '/image',
  ADD = '/add',
  CREATE = '/create',
  CONFIRM = '/confirm-creation',
  UPDATE = '/update',
  DELETE = '/delete',
  EXTERNAL = '/external',
  PUBLIC = '/public',
}

export enum IMAGE_LOAD_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export enum QUERY_KEYS {
  ALBUMS_LIST = 'albums list',
  DRAFT_ALBUM = 'draft album',
}

export enum CHECK_STATUS_CONDITIONS {
  UPLOAD = 'is_upload_completed',
  PROCESSING = 'is_selector_processed',
  ML_PROCESSED = 'is_ml_processed',
}
