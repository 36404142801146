import { useContext } from 'react';
import { useMutation, UseMutateFunction } from 'react-query';

import { signUpWithEmail, getCurrentUser } from 'services/firebaseApi/auth';
import history from 'helpers/history';
import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';
import { TSignUpUserDataWithEmail, TError } from 'types/models';
import routes from 'constants/routes';

interface ISignUp {
  mutate: UseMutateFunction<
    string | undefined,
    TError,
    TSignUpUserDataWithEmail,
    unknown
  >;
  isLoading: boolean;
  error: TError | null;
}

export default (): ISignUp => {
  const {
    dispatch,
    state: {
      user: { isInviteRedirect },
    },
  } = useContext(AppContext);

  const { mutate, isLoading, error } = useMutation<
    string | undefined,
    TError,
    TSignUpUserDataWithEmail
  >((userData) => signUpWithEmail(userData), {
    onSuccess: (token) => {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const { uid, email, displayName } = currentUser;

        const redirect = isInviteRedirect
          ? `${routes.PUBLIC_VIEW}/${isInviteRedirect}`
          : routes.MAIN;

        dispatch({
          type: ACTION_TYPES.SET_USER,
          payload: {
            id: uid,
            email,
            username: displayName,
            photoURL: null,
            token,
            isSocialSignIn: false,
          },
        });

        history.push(redirect);
      }
    },
  });

  return { mutate, isLoading, error };
};
