import { useContext, useState } from 'react';
import {
  InfiniteData,
  useInfiniteQuery,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from 'react-query';

import { checkAlbumImagesStatus } from 'services/api/images';
import { AppContext } from 'context/AppProvider';
import { TImagesPage, TNewPhoto } from 'types/models';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';

interface ICheckUploadDetails {
  isLoading: boolean;
  data?: InfiniteData<TImagesPage>;
  hasNextPage?: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<TImagesPage>>;
}

export default (): ICheckUploadDetails => {
  const [isUploadCompleted, setUploadCompleted] =
    useState<boolean | undefined>(false);
  const {
    state: {
      user: { token },
    },
    uploadAlbum: { data: album, isCheckStatusEnabled },
    dispatch,
    dispatchUploadAlbum,
  } = useContext(AppContext);

  const setPhotos = (data: TNewPhoto[]) => {
    const type = isCheckStatusEnabled
      ? UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_PHOTOS
      : UPLOAD_ALBUM_ACTION_TYPES.SET_PHOTOS_PAGE;

    dispatchUploadAlbum({
      type,
      payload: data,
    });
  };

  const refetchInterval = isCheckStatusEnabled ? 3000 : undefined;

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery(
    ['album images', album?.key],
    ({ pageParam }) =>
      checkAlbumImagesStatus({
        dispatch,
        albumKey: album?.key,
        token,
        nextPageUrl: pageParam,
        setPhotos,
        isCheckStatusEnabled,
        isUploadCompleted,
      }),
    {
      refetchInterval,
      getNextPageParam: (page) => page.next,
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: ({ pages }) => {
        const lastPageIndex = pages.length - 1;
        const lastPage = pages[lastPageIndex];
        const { size } = lastPage;

        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_ALBUM_SIZE,
          payload: size,
        });

        setUploadCompleted(lastPage.is_upload_completed);

        if (typeof isCheckStatusEnabled === 'string') {
          const isCompleted = lastPage[isCheckStatusEnabled];

          if (isCompleted) {
            dispatchUploadAlbum({
              type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED,
              payload: false,
            });
            dispatchUploadAlbum({
              type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
              payload: {
                is_selector_processed: lastPage.is_selector_processed,
              },
            });
          }
        }
      },
    }
  );

  return { isLoading, data, hasNextPage, fetchNextPage };
};
