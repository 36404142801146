const sliceArrayIntoChunks = <Type>(
  array: Array<Type>,
  chunkSize = 3
): Type[][] => {
  const result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
};

export default sliceArrayIntoChunks;
