import React from 'react';

import logoImg from 'assets/images/logo.svg';

import styles from './index.module.scss';

const Logo: React.FunctionComponent = () => (
  <figure className={styles.logo}>
    <img src={logoImg} alt="logo" className={styles.logo__img} />
    <figcaption className={styles.logo__title}>Create. Edit. Share.</figcaption>
  </figure>
);

export default Logo;
