import { IState } from 'context/AppProvider';
import { TActions, ACTION_TYPES } from 'context/modules/main/actions';

export default (state: IState, action: TActions): IState => {
  switch (action.type) {
    case ACTION_TYPES.SET_USER: {
      return { ...state, user: action.payload };
    }
    case ACTION_TYPES.SET_THEME: {
      const { ui } = state;
      const newUi = { ...ui, isLightMode: !ui.isLightMode };

      return { ...state, ui: newUi };
    }
    case ACTION_TYPES.SET_MODAL_PARAMETERS: {
      return { ...state, modalParameters: action.payload };
    }
    case ACTION_TYPES.SET_UI_SETTINGS: {
      return { ...state, ui: action.payload };
    }
    case ACTION_TYPES.SET_USER_TOKEN: {
      return { ...state, user: { ...state.user, token: action.payload } };
    }
    case ACTION_TYPES.SET_USER_INVITE_REDIRECT: {
      return {
        ...state,
        user: { ...state.user, isInviteRedirect: action.payload },
      };
    }
    default:
      return state;
  }
};
