import React, { useContext, useEffect } from 'react';
import { InfiniteData } from 'react-query';

import { IInfiniteQueryOptions, TImagesPage } from 'types/models';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';

import Gallery from 'components/common/Gallery';

import photoTemplate from 'assets/images/photo-template-small.png';

import styles from './index.module.scss';

interface IPhotosList {
  data: InfiniteData<TImagesPage>;
  infiniteQueryOptions: IInfiniteQueryOptions;
}

const PhotosList = React.forwardRef<HTMLUListElement, IPhotosList>(
  ({ data, infiniteQueryOptions }, ref) => {
    const {
      dispatch,
      state: { modalParameters },
    } = useContext(AppContext);

    useEffect(() => {
      if (modalParameters.visible) {
        dispatch({
          type: ACTION_TYPES.SET_MODAL_PARAMETERS,
          payload: { ...modalParameters, galleryData: data },
        });
      }
    }, [modalParameters.visible, data.pages.length]);

    const onClick = (e: React.MouseEvent<HTMLLIElement>) => {
      const { id } = e.currentTarget;

      dispatch({
        type: ACTION_TYPES.SET_MODAL_PARAMETERS,
        payload: {
          visible: true,
          content: (
            <Gallery
              startKey={id}
              infiniteQueryOptions={infiniteQueryOptions}
            />
          ),
          wrapperStyles: styles.gallery,
        },
      });
    };

    return (
      <ul ref={ref} className={styles.list}>
        {data.pages.map(({ results }) =>
          results.map((photo) => {
            const { key, small_remote_url: smallUrl, remote_url: url } = photo;
            const imageSrc = smallUrl || url || photoTemplate;

            return (
              <li
                role="presentation"
                key={key}
                className={styles.list__item}
                onClick={onClick}
                id={key}
              >
                <img src={imageSrc} alt="item" loading="lazy" />
              </li>
            );
          })
        )}
      </ul>
    );
  }
);

export default PhotosList;
