import React, { useContext } from 'react';

import { COLORS } from 'constants/constants';
import { AppContext } from 'context/AppProvider';

const LogoutSVG: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = isLightMode ? COLORS.BLACK : COLORS.WHITE;

  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5003 12.457C11.7545 12.457 11.9982 12.3561 12.178 12.1763C12.3577 11.9966 12.4587 11.7529 12.4587 11.4987V1.91536C12.4587 1.6612 12.3577 1.41744 12.178 1.23772C11.9982 1.058 11.7545 0.957031 11.5003 0.957031C11.2462 0.957031 11.0024 1.058 10.8227 1.23772C10.643 1.41744 10.542 1.6612 10.542 1.91536V11.4987C10.542 11.7529 10.643 11.9966 10.8227 12.1763C11.0024 12.3561 11.2462 12.457 11.5003 12.457Z"
        fill={color}
      />
      <path
        d="M15.8991 2.98219C15.6729 2.86527 15.4095 2.84301 15.1669 2.92029C14.9242 2.99757 14.7222 3.16806 14.6053 3.39427C14.4884 3.62048 14.4661 3.88387 14.5434 4.12649C14.6207 4.36912 14.7912 4.57111 15.0174 4.68802C16.5445 5.47641 17.7624 6.75501 18.4756 8.31859C19.1888 9.88217 19.356 11.64 18.9504 13.31C18.5447 14.98 17.5897 16.4653 16.2387 17.5275C14.8876 18.5896 13.2189 19.167 11.5003 19.167C9.78177 19.167 8.11304 18.5896 6.762 17.5275C5.41096 16.4653 4.45597 14.98 4.05031 13.31C3.64465 11.64 3.81185 9.88217 4.52508 8.31859C5.23831 6.75501 6.45619 5.47641 7.98325 4.68802C8.09525 4.63013 8.19476 4.55075 8.27609 4.4544C8.35742 4.35805 8.41897 4.24663 8.45723 4.12649C8.4955 4.00636 8.50973 3.87986 8.49911 3.75423C8.48849 3.62859 8.45322 3.50628 8.39533 3.39427C8.33744 3.28227 8.25806 3.18276 8.16171 3.10143C8.06536 3.02011 7.95394 2.95855 7.8338 2.92029C7.71367 2.88202 7.58717 2.86779 7.46154 2.87841C7.3359 2.88904 7.21359 2.9243 7.10158 2.98219C5.19324 3.96811 3.67145 5.56655 2.78043 7.521C1.8894 9.47545 1.68081 11.6726 2.18812 13.7598C2.69543 15.847 3.88924 17.7032 5.57795 19.0307C7.26666 20.3581 9.35236 21.0797 11.5003 21.0797C13.6483 21.0797 15.734 20.3581 17.4227 19.0307C19.1114 17.7032 20.3052 15.847 20.8125 13.7598C21.3199 11.6726 21.1113 9.47545 20.2202 7.521C19.3292 5.56655 17.8074 3.96811 15.8991 2.98219Z"
        fill={color}
      />
    </svg>
  );
};

export default LogoutSVG;
