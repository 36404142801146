import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

interface IInactiveAlbumSVG {
  isDark?: boolean;
}

const InactiveAlbumSVG: React.FunctionComponent<IInactiveAlbumSVG> = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const circleColor = isLightMode ? COLORS.GREY_1 : COLORS.BLACK;
  const eyeColor = isLightMode ? COLORS.BLACK : COLORS.GREY_2;

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill={circleColor} />
      <path
        d="M21.8334 15.1695C22.0536 14.6387 22.0536 14.03 21.8334 13.4987C20.5327 10.361 17.4571 8.33364 13.9982 8.33364C12.5454 8.33364 11.1606 8.69158 9.94647 9.3397L7.80237 7.19548C7.54161 6.93484 7.12031 6.93484 6.85954 7.19548C6.5989 7.45612 6.5989 7.87755 6.85954 8.13819L15.3443 16.6229L18.0471 19.3259L20.1934 21.4721C20.324 21.6027 20.4947 21.668 20.6654 21.668C20.836 21.668 21.0067 21.6027 21.1367 21.4727C21.3975 21.212 21.3975 20.7906 21.1367 20.53L19.1946 18.5878C20.3394 17.7164 21.2602 16.553 21.8334 15.1695ZM12.6481 12.0412C13.0434 11.8045 13.5041 11.6672 13.9982 11.6672C15.469 11.6672 16.665 12.8633 16.665 14.3341C16.665 14.8281 16.5277 15.2888 16.291 15.6842L12.6481 12.0412Z"
        fill={eyeColor}
      />
      <path
        d="M13.9997 17.0003C12.5289 17.0003 11.3328 15.8041 11.3328 14.3334C11.3328 14.2367 11.3514 14.1453 11.3614 14.052L8.0439 10.7344C7.25184 11.5065 6.60445 12.4365 6.16508 13.498C5.94497 14.0287 5.94497 14.6374 6.16508 15.1688C7.46512 18.3064 10.5407 20.3339 13.9997 20.3339C15.0778 20.3339 16.1186 20.1365 17.0799 19.7705L14.281 16.9716C14.1877 16.9816 14.0964 17.0003 13.9997 17.0003Z"
        fill={eyeColor}
      />
    </svg>
  );
};

export default InactiveAlbumSVG;
