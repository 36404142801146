import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import useScrollToTop from 'hooks/useScrollToTop';
import { loadGapi } from 'services/googlePicker';
import { loadDropbox } from 'services/dropboxChooser';
import routes from 'constants/routes';

import Albums from 'pages/Albums';
import Album from 'pages/Album';
import Profile from 'pages/Profile';
import CreateAlbumPhotos from 'pages/CreateAlbumPhotos';
import CreateAlbumSetting from 'pages/CreateAlbumSetting';
import EditAlbumSettings from 'pages/EditAlbumSettings';
import Invite from 'pages/Invite';
import AlbumPublic from 'pages/AlbumPublic';

const PrivateRoutes: React.FunctionComponent = () => {
  useScrollToTop();

  useEffect(() => {
    loadGapi();
    loadDropbox();
  }, []);

  return (
    <Switch>
      <Route exact path={routes.MAIN} component={Albums} />
      <Route exact path={`${routes.INVITE}/:hash`} component={Invite} />
      <Route exact path={`${routes.ALBUM}/:key`} component={Album} />
      <Route path={routes.PROFILE} component={Profile} />
      <Route path={routes.CREATE_ALBUM_PHOTOS} component={CreateAlbumPhotos} />
      <Route
        path={`${routes.CREATE_ALBUM_SETTINGS}/:key`}
        component={CreateAlbumSetting}
      />
      <Route
        path={`${routes.EDIT_ALBUM_SETTINGS}/:key`}
        component={EditAlbumSettings}
      />
      <Route path={`${routes.PUBLIC_VIEW}/:hash`} component={AlbumPublic} />
      <Redirect to={routes.MAIN} />
    </Switch>
  );
};

export default PrivateRoutes;
