import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useQueryClient } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';

import routes from 'constants/routes';
import { QUERY_KEYS } from 'constants/constants';

import history from 'helpers/history';

import useGetAlbum from 'hooks/api/useGetAlbum';
import useUpdateAlbum from 'hooks/api/useUpdateAlbum';
import useCheckUploadDetails from 'hooks/api/useCheckUploadDetails';

import Header from 'components/common/Header';
import MainContainer from 'components/common/MainContainer';
import EditAlbum from 'components/common/EditAlbumSettings';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import AddPhotos from 'components/common/AddPhotos';

import ArrowBackSVG from 'assets/icons/arrow-back';

import styles from './index.module.scss';

interface IParams {
  key: string;
}

const EditAlbumSettings: React.FunctionComponent = () => {
  const [isSelectCoverMode, setSelectCoverMode] = useState<boolean>(false);
  const { key } = useParams<IParams>();
  const {
    state: {
      ui: { isLightMode },
    },
    uploadAlbum: { data, loading, error },
    dispatchUploadAlbum,
  } = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_PHOTOS,
      payload: [],
    });
  }, []);

  const { data: album } = useGetAlbum({
    key,
    options: { refetchOnWindowFocus: false },
  });

  const { hasNextPage, fetchNextPage } = useCheckUploadDetails();

  const { cover_picture: mainPhoto, title, images } = data || {};

  const onSuccess = () => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
    });

    history.push(`${routes.ALBUM}/${key}`);
  };

  const { mutate: saveChanges, isLoading } = useUpdateAlbum(
    undefined,
    {
      onError: (err) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
          payload: err.message,
        });
      },
    },
    onSuccess
  );

  useEffect(() => {
    const isAlbumData = album && !data?.key;

    if (isAlbumData) {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
        payload: { ...album, images: [] },
      });
    }
  }, [album]);

  const cancel = () => {
    if (isSelectCoverMode) {
      const { cover_picture: oldCover } = data || {};

      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
        payload: { cover_picture: oldCover },
      });
      setSelectCoverMode(false);

      return;
    }

    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
    });

    queryClient.resetQueries(QUERY_KEYS.ALBUMS_LIST);

    history.push(`${routes.ALBUM}/${key}`);
  };

  const save = () => {
    if (isSelectCoverMode) {
      setSelectCoverMode(false);

      return;
    }

    if (data) {
      const { cover_picture: newCover, title: newTitle } = data || {};

      saveChanges({ cover_picture: newCover, title: newTitle });

      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
      });
    }
  };

  const resetError = () => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.RESET_ERROR,
    });
  };

  const moduleClasses = classNames.bind(styles);
  const saveClasses = moduleClasses('save', {
    'save-dark': !isLightMode,
  });

  return (
    <MainContainer>
      <Header
        leftButton={
          <Button
            classes={styles.cancel}
            IconComponent={ArrowBackSVG}
            title=""
            onClick={cancel}
            disabled={loading}
          />
        }
        fixedOnScroll={1}
        heading={isSelectCoverMode ? 'Change album cover' : 'Edit Album'}
        rightButton={
          isLoading ? (
            <div className={styles.loader}>
              <Spinner size={18} width={3} />
            </div>
          ) : (
            <Button
              classes={saveClasses}
              title={isSelectCoverMode ? 'Set cover' : 'Save'}
              onClick={save}
              disabled={loading}
            />
          )
        }
      />

      {data?.key && (
        <EditAlbum
          photos={images}
          mainPhoto={mainPhoto}
          title={title}
          isSelectCoverMode={isSelectCoverMode}
          setChangeCoverMode={setSelectCoverMode}
          loading={loading}
          error={error}
          resetError={resetError}
          infiniteQueryOptions={{
            hasNextPage: !!hasNextPage,
            fetchNextPage,
          }}
        />
      )}

      <AddPhotos
        isFixed
        isSelectCoverMode={isSelectCoverMode}
        save={save}
        isLoading={isLoading}
      />
    </MainContainer>
  );
};

export default EditAlbumSettings;
