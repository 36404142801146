import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from 'react-share';
import classNames from 'classnames/bind';
import { useQueryClient } from 'react-query';

import Switcher from 'components/common/Switcher';
import Spinner from 'components/common/Spinner';

import routes from 'constants/routes';

import useUpdateAlbum from 'hooks/api/useUpdateAlbum';
import useGetShareLink from 'hooks/api/useGetShareLink';

import { IAlbumResponse } from 'types/models';

import EditAlbumSVG from 'assets/icons/edit-album';

import styles from './index.module.scss';

interface IAlbumMenu {
  album?: IAlbumResponse;
}

const AlbumMenu: React.FunctionComponent<IAlbumMenu> = ({ album }) => {
  const [isSetActivityAlert, setActivityAlert] = useState<boolean>(false);

  const { key, is_active: isActiveAlbum, title } = album || {};
  const isGetLinkEnabled = !!key && isActiveAlbum;

  const shareLink = useGetShareLink(key, { enabled: isGetLinkEnabled });
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateAlbum(
    key,
    {
      onError: () => {
        setActivityAlert(true);
      },
    },
    (response) => {
      if (album && album?.key) {
        queryClient.setQueryData<IAlbumResponse>(album.key, (data) => ({
          ...data,
          ...response,
        }));
      }
    },
    true
  );

  const onActiveChange = () => {
    if (isActiveAlbum) {
      setActivityAlert(true);
      return;
    }

    mutate({ is_active: true });
  };

  const closeAlert = () => {
    setActivityAlert(false);
  };

  const submitDeactivate = () => {
    mutate({ is_active: false });
    setActivityAlert(false);
  };

  const messageBody = `You are invited to see album ${title}`;

  const moduleClasses = classNames.bind(styles);
  const menuClasses = moduleClasses('menu', {
    'menu-alert': isSetActivityAlert,
  });

  return (
    <details className={styles.details}>
      <summary>
        <EditAlbumSVG />
      </summary>
      {isSetActivityAlert && (
        <div className={styles.alert}>
          <p>
            {isActiveAlbum
              ? 'If you deactivate album, photos quality will be lost.You will be able to add or remove photos again upon editing.'
              : 'You can only have three active albums at a time. To set it active, please deactivate any other album.'}
          </p>
          <button
            type="button"
            className={styles.alert__button}
            onClick={closeAlert}
          >
            {isActiveAlbum ? 'Cancel' : 'Got it'}
          </button>

          {isActiveAlbum && (
            <button
              type="button"
              className={styles.alert__button}
              onClick={submitDeactivate}
            >
              Agree
            </button>
          )}
        </div>
      )}
      <ul className={menuClasses}>
        <li className={`${styles.menu__item} ${styles.activate}`}>
          {isLoading ? (
            <Spinner size={27} width={4} disableModeSwitch />
          ) : (
            'Activate'
          )}

          <Switcher
            isBlue
            checked={!!isActiveAlbum}
            onChange={onActiveChange}
            isLoading={isLoading}
          />
        </li>
        {isActiveAlbum && (
          <>
            <li className={styles.menu__item}>
              <Link
                to={`${routes.EDIT_ALBUM_SETTINGS}/${key}`}
                className={`${styles.menu__link} ${styles.edit}`}
              >
                Edit album
              </Link>
            </li>

            {shareLink && (
              <>
                <li className={styles.menu__item}>
                  <EmailShareButton
                    subject={`Invite to view '${title}'`}
                    body={messageBody}
                    url={shareLink}
                    className={`${styles.menu__link} ${styles.email}`}
                  >
                    Share via Email
                  </EmailShareButton>
                </li>
                <li className={styles.menu__item}>
                  <FacebookShareButton
                    url={shareLink}
                    className={`${styles.menu__link} ${styles.facebook}`}
                    quote={messageBody}
                  >
                    Share via Facebook
                  </FacebookShareButton>
                </li>
                <li className={styles.menu__item}>
                  <WhatsappShareButton
                    url={shareLink}
                    className={`${styles.menu__link} ${styles.whatsapp}`}
                    title={messageBody}
                  >
                    Share via Whatsapp
                  </WhatsappShareButton>
                </li>
              </>
            )}
          </>
        )}
      </ul>
    </details>
  );
};

export default AlbumMenu;
