import React, { useContext, useMemo } from 'react';

import { COLORS } from 'constants/constants';
import { IIconProps } from 'types/models';
import { AppContext } from 'context/AppProvider';

const MailSVG: React.FunctionComponent<IIconProps> = ({
  isDark,
  disableModeSwitch,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = useMemo((): string => {
    if (!disableModeSwitch && !isLightMode) {
      return isDark ? COLORS.WHITE : COLORS.GREY;
    }

    return isDark ? COLORS.BLACK : COLORS.GREY;
  }, [isDark, isLightMode, disableModeSwitch]);

  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2422 0.96875H1.75781C0.786602 0.96875 0 1.81476 0 2.84766V14.1211C0 15.1601 0.792383 16 1.75781 16H18.2422C19.2053 16 20 15.1637 20 14.1211V2.84766C20 1.8166 19.2165 0.96875 18.2422 0.96875ZM17.996 2.22135C17.6369 2.60319 11.4564 9.17469 11.243 9.40157C10.9109 9.75648 10.4695 9.95188 10 9.95188C9.53047 9.95188 9.08906 9.75644 8.75594 9.4004C8.61242 9.2478 2.50012 2.74887 2.00398 2.22135H17.996ZM1.17188 13.8661V3.1034L6.23586 8.48771L1.17188 13.8661ZM2.00473 14.7474L7.06672 9.37109L7.9284 10.2873C8.48176 10.8788 9.21746 11.2045 10 11.2045C10.7825 11.2045 11.5182 10.8788 12.0705 10.2885L12.9333 9.37109L17.9953 14.7474H2.00473ZM18.8281 13.8661L13.7641 8.48771L18.8281 3.1034V13.8661Z"
        fill={color}
      />
    </svg>
  );
};

export default MailSVG;
