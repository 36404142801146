import React, { useContext, useMemo } from 'react';

import { COLORS } from 'constants/constants';
import { AppContext } from 'context/AppProvider';
import { IIconProps } from 'types/models';

interface IProfileProps extends IIconProps {
  width?: string;
  height?: string;
}

const ProfileSVG: React.FunctionComponent<IProfileProps> = ({
  isDark,
  width = '20',
  height = '20',
  isInput,
  disableModeSwitch,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = useMemo((): string => {
    if (!disableModeSwitch && !isLightMode) {
      return isDark ? COLORS.WHITE : COLORS.GREY;
    }

    return isDark ? COLORS.BLACK : COLORS.GREY;
  }, [isDark, isLightMode, disableModeSwitch]);

  const isIconLightMode = !isInput && isLightMode;
  const isCountered = isInput || isIconLightMode;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isCountered ? (
        <>
          <g clipPath="url(#clip0)">
            <path
              d={
                isInput
                  ? 'M10 1C7.60051 1 5.64844 2.95211 5.64844 5.35152C5.64844 7.75102 7.60055 9.70312 10 9.70312C12.3995 9.70312 14.3515 7.75102 14.3515 5.35156C14.3515 2.95211 12.3995 1 10 1ZM10 8.53121C8.24668 8.53121 6.82031 7.1048 6.82031 5.35152C6.82031 3.59828 8.24672 2.17188 10 2.17188C11.7532 2.17188 13.1796 3.59828 13.1796 5.35152C13.1796 7.1048 11.7532 8.53121 10 8.53121Z'
                  : 'M15.9996 1.59961C12.1604 1.59961 9.03711 4.72298 9.03711 8.56205C9.03711 12.4012 12.1605 15.5246 15.9996 15.5246C19.8387 15.5246 22.962 12.4012 22.962 8.56211C22.962 4.72298 19.8387 1.59961 15.9996 1.59961ZM15.9996 13.6495C13.1943 13.6495 10.9121 11.3673 10.9121 8.56205C10.9121 5.75686 13.1944 3.47461 15.9996 3.47461C18.8048 3.47461 21.087 5.75686 21.087 8.56205C21.087 11.3673 18.8047 13.6495 15.9996 13.6495Z'
              }
              fill={color}
              stroke={color}
              strokeWidth="0.3"
            />
            <path
              d={
                isInput
                  ? 'M10 11.2969C5.52418 11.2969 1.88281 14.9382 1.88281 19.4141C1.88281 19.7377 2.14516 20 2.46875 20H17.5313C17.8548 20 18.1172 19.7377 18.1172 19.4141C18.1172 14.9382 14.4759 11.2969 10 11.2969ZM3.07918 18.8281C3.37762 15.2716 6.36766 12.4688 10.0001 12.4688C13.6324 12.4688 16.6224 15.2716 16.9209 18.8281H3.07918Z'
                  : 'M15.9993 17.0742C8.83791 17.0742 3.01172 22.9004 3.01172 30.0617C3.01172 30.5795 3.43147 30.9992 3.94922 30.9992H28.0492C28.567 30.9992 28.9867 30.5795 28.9867 30.0617C28.9867 22.9004 23.1606 17.0742 15.9993 17.0742ZM4.92591 29.1242C5.40341 23.4337 10.1875 18.9492 15.9993 18.9492C21.8111 18.9492 26.5951 23.4337 27.0726 29.1242H4.92591Z'
              }
              fill={color}
              stroke={color}
              strokeWidth="0.3"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width={width} height={height} fill={COLORS.WHITE} />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <path
            d="M15.9996 1.59961C12.1605 1.59961 9.03711 4.72298 9.03711 8.56205C9.03711 12.4012 12.1605 15.5246 15.9996 15.5246C19.8387 15.5246 22.962 12.4012 22.962 8.56211C22.962 4.72298 19.8387 1.59961 15.9996 1.59961Z"
            fill={COLORS.WHITE}
          />
          <path
            d="M15.9993 17.0742C8.83791 17.0742 3.01172 22.9004 3.01172 30.0617C3.01172 30.5795 3.43141 30.9992 3.94922 30.9992H28.0492C28.567 30.9992 28.9867 30.5795 28.9867 30.0617C28.9867 22.9004 23.1606 17.0742 15.9993 17.0742Z"
            fill={COLORS.WHITE}
          />
        </>
      )}
    </svg>
  );
};

export default ProfileSVG;
