import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { ACTION_TYPES } from 'context/modules/main/actions';

import Header from 'components/common/Header';
import MainContainer from 'components/common/MainContainer';
import Button from 'components/common/Button';
import EditAlbumPhotos from 'components/common/EditAlbumPhotos';
import AlbumLengthHandler from 'components/common/AlbumLengthHandler';
import Spinner from 'components/common/Spinner';

import useBeforeUnload from 'hooks/useBeforeUnload';
import useCancelAlbumCreation from 'hooks/api/useCancelAlbumCreation';
import useConfirmAlbumCreation from 'hooks/api/useConfirmAlbumCreation';
import useCheckUploadDetails from 'hooks/api/useCheckUploadDetails';
import useSubmitAlbum from 'hooks/api/useSubmitAlbum';

import DraftAlbumWatcher from 'watchers/draftAlbum';
import ErrorWatcher from 'watchers/scrollToError';

import { UPLOADED_PHOTOS_MAX_LENGTH } from 'constants/constants';

import styles from './index.module.scss';

const CreateAlbumPhotos: React.FunctionComponent = () => {
  const [albumLength, setAlbumLength] = useState<number>(0);
  const {
    state: {
      ui: { isLightMode },
      modalParameters,
    },
    uploadAlbum,
    dispatch,
    dispatchUploadAlbum,
  } = useContext(AppContext);

  useEffect(() => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_PHOTOS,
      payload: [],
    });
  }, []);

  const { loading, data, error, isCheckStatusEnabled } = uploadAlbum;
  const { hasNextPage, fetchNextPage } = useCheckUploadDetails();

  useEffect(() => {
    if (modalParameters.visible) {
      dispatch({
        type: ACTION_TYPES.SET_MODAL_PARAMETERS,
        payload: { ...modalParameters, newAlbumGalleryData: data?.images },
      });
    }
  }, [modalParameters.visible, data?.images?.length]);

  useBeforeUnload();
  const cancel = useCancelAlbumCreation();
  const { mutate: confirm, isLoading: isConfirmLoading } =
    useConfirmAlbumCreation();
  const { submit, isLoading: isSubmitLoading } = useSubmitAlbum();

  const uploadedPhotos = data?.images;

  const resetError = () => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.RESET_ERROR,
    });
  };

  const submitCreation = () => {
    submit(albumLength, uploadedPhotos);
  };

  const save = () => {
    confirm();
  };

  const openModal = () => {
    dispatch({
      type: ACTION_TYPES.SET_MODAL_PARAMETERS,
      payload: { visible: true, isChoosePhotos: true },
    });
  };

  const moduleClasses = classNames.bind(styles);
  const cancelClasses = moduleClasses('cancel', {
    'cancel-dark': !isLightMode,
  });
  const settingsClasses = moduleClasses('settings', {
    'settings-dark': !isLightMode,
  });

  const isSaveButtonVisible = data?.is_selector_processed && !isConfirmLoading;
  const disabledActions = loading || isConfirmLoading || isSubmitLoading;
  const addMoreDisabled =
    data?.size === UPLOADED_PHOTOS_MAX_LENGTH || disabledActions;

  return (
    <MainContainer style={styles.wrapper}>
      <DraftAlbumWatcher />
      <ErrorWatcher />

      <Header
        leftButton={
          <Button title="Cancel" classes={cancelClasses} onClick={cancel} />
        }
        heading="Create album"
        rightButton={
          <div className={styles.buttons}>
            {isSaveButtonVisible && (
              <Button
                title="Save"
                onClick={save}
                disabled={disabledActions}
                classes={cancelClasses}
              />
            )}

            {isConfirmLoading && <Spinner size={37} width={6} />}

            <Button
              title="Submit"
              isSmall
              onClick={submitCreation}
              disabled={disabledActions}
              additionalClasses={styles.submit}
            />
          </div>
        }
        isShadowed
        fixedOnScroll={1}
      />

      <EditAlbumPhotos
        photos={uploadedPhotos}
        infiniteQueryOptions={{
          hasNextPage: !!hasNextPage,
          fetchNextPage,
        }}
        error={error}
        loading={loading || !!isCheckStatusEnabled}
        resetError={resetError}
        albumLength={albumLength}
        setAlbumLength={setAlbumLength}
        albumSize={data?.size}
        disabledActions={disabledActions}
      />

      <div className={settingsClasses}>
        <AlbumLengthHandler
          albumLength={albumLength}
          setAlbumLength={setAlbumLength}
          maxValue={data?.size}
        />
        <div className={styles.settings__buttons}>
          {loading ? (
            <Spinner size={37} width={6} />
          ) : (
            <Button
              title="Add more photos"
              isSmall
              onClick={openModal}
              classes={cancelClasses}
              disabled={addMoreDisabled}
            />
          )}

          {isSubmitLoading ? (
            <Spinner size={37} width={6} />
          ) : (
            <Button
              title="Create album"
              isSmall
              onClick={submitCreation}
              disabled={disabledActions}
              classes={cancelClasses}
            />
          )}
        </div>
      </div>
    </MainContainer>
  );
};

export default CreateAlbumPhotos;
