import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';

import useDeletePhoto from 'hooks/api/useDeletePhoto';
import useLikePhoto from 'hooks/api/useLikePhoto';

import { TNewPhoto } from 'types/models';

import Spinner from 'components/common/Spinner';
import Gallery from 'components/common/Gallery';

import LikeSVG from 'assets/icons/like';
import photoTemplate from 'assets/images/photo-template-small.png';

import styles from './index.module.scss';

interface IUploadedPhoto {
  data: TNewPhoto;
  isSmall?: boolean;
  resetError?: () => void;
  loading?: boolean;
  setNewCover?: (photo: TNewPhoto) => void;
  mainPhotoKey?: string;
  disabledActions?: boolean;
}

const UploadedPhoto: React.FunctionComponent<IUploadedPhoto> = ({
  data,
  isSmall,
  resetError,
  loading,
  setNewCover,
  mainPhotoKey,
  disabledActions,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
    dispatch,
  } = useContext(AppContext);

  const { liked, key, small_remote_url: smallUrl, remote_url: url } = data;

  const { mutate: deletePhoto, isLoading: isDeleteLoading } =
    useDeletePhoto(key);

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    deletePhoto();

    if (resetError) {
      resetError();
    }
  };

  const { mutate: setLike, isLoading: isLikeLoading } = useLikePhoto(key);

  const inputHandler = () => {
    setLike(!liked);

    if (resetError) {
      resetError();
    }
  };

  const setNewAlbumCover = () => {
    if (setNewCover) {
      setNewCover(data);
    }
  };

  const isLoading = loading || isLikeLoading || isDeleteLoading;

  const openFullScreen = () => {
    if (isLoading || disabledActions) return;

    dispatch({
      type: ACTION_TYPES.SET_MODAL_PARAMETERS,
      payload: {
        visible: true,
        content: <Gallery startKey={key} />,
        wrapperStyles: styles.gallery,
      },
    });
  };

  const isMainPhoto = mainPhotoKey === key;

  const moduleClasses = classNames.bind(styles);
  const itemClasses = moduleClasses('item', {
    'item-small': isSmall,
    'item-cover-mode': !!setNewCover,
    'item-active-cover': !!setNewCover && isMainPhoto,
    'item-active-cover-dark': !!setNewCover && isMainPhoto && !isLightMode,
  });

  const imageSrc = smallUrl || url || photoTemplate;
  const isActions = imageSrc !== photoTemplate;

  const likeClasses = moduleClasses('like', {
    'like-disabled': isLoading,
  });

  return (
    <li
      className={itemClasses}
      role="presentation"
      onClick={setNewCover ? setNewAlbumCover : openFullScreen}
    >
      {isActions && (
        <>
          {isDeleteLoading ? (
            <div className={styles.spinner}>
              <Spinner size={isSmall ? 24 : 35} width={4} />
            </div>
          ) : (
            <button
              type="button"
              className={styles.delete}
              onClick={onDelete}
              disabled={isLoading || disabledActions}
            >
              Delete
            </button>
          )}
          <label
            htmlFor={key}
            className={likeClasses}
            onClick={(e) => e.stopPropagation()}
            role="presentation"
          >
            <input
              type="checkbox"
              id={key}
              defaultChecked={liked}
              onChange={inputHandler}
              disabled={isLoading || disabledActions}
            />
            {isLikeLoading ? <Spinner size={24} width={4} /> : <LikeSVG />}
            Like
          </label>
        </>
      )}

      <img src={imageSrc} alt="album item" className={styles.img} />
    </li>
  );
};

export default UploadedPhoto;
