import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

const PlusSmall: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const fill = isLightMode ? COLORS.BLACK : COLORS.WHITE;

  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 22C17.0111 22 22.0136 17.0112 22.0136 11C22.0136 4.98885 16.9975 0 10.9864 0C4.98884 0 0 4.98885 0 11C0 17.0112 5.00249 22 11 22ZM11 19.9281C6.05204 19.9281 2.09914 15.9616 2.09914 11C2.09914 6.03843 6.03841 2.07188 10.9864 2.07188C15.9479 2.07188 19.9145 6.03843 19.9281 11C19.9418 15.9616 15.9616 19.9281 11 19.9281ZM7.01982 12.0223H9.96406V14.9802C9.96406 15.5936 10.373 16.0161 10.9727 16.0161C11.5998 16.0161 12.0087 15.5936 12.0087 14.9802V12.0223H14.9802C15.5936 12.0223 16.0161 11.6134 16.0161 11.0136C16.0161 10.4003 15.5936 9.9777 14.9802 9.9777H12.0087V7.03345C12.0087 6.40644 11.5998 5.98388 10.9727 5.98388C10.373 5.98388 9.96406 6.40644 9.96406 7.03345V9.9777H7.01982C6.3928 9.9777 5.97027 10.4003 5.97027 11.0136C5.97027 11.6134 6.3928 12.0223 7.01982 12.0223Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusSmall;
