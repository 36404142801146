import React, { useContext, useMemo } from 'react';
import classNames from 'classnames/bind';

import useUpdateProfile from 'hooks/api/useUpdateProfile';

import {
  TUpdateProfileData,
  IFormData,
  IInputData,
  IUpdateProfileProps,
} from 'types/models';

import { validationSchemaChangeProfile } from 'helpers/validation';

import { INPUT_TYPES, INPUT_NAMES } from 'constants/constants';

import { AppContext } from 'context/AppProvider';

import Form from 'components/common/Form';

import ProfileSVG from 'assets/icons/profile';
import MailSVG from 'assets/icons/mail';
import LockSVG from 'assets/icons/lock';

import styles from './index.module.scss';

const fieldsParameters: Array<IInputData> = [
  {
    name: INPUT_NAMES.AVATAR,
    type: INPUT_TYPES.FILE,
    required: false,
  },
  {
    name: 'username',
    type: INPUT_TYPES.TEXT,
    required: false,
    placeholder: 'Username',
    Icon: ProfileSVG,
  },
  {
    name: 'email',
    type: INPUT_TYPES.EMAIL,
    required: false,
    placeholder: 'Email',
    Icon: MailSVG,
  },
  {
    name: 'password',
    type: INPUT_TYPES.PASSWORD,
    required: true,
    placeholder: 'Password',
    Icon: LockSVG,
    isPasswordEyeHidden: true,
  },
];

const UpdateProfileForm: React.FunctionComponent<IUpdateProfileProps> = ({
  setSuccess,
  setChangePassword,
}) => {
  const {
    state: {
      user: { username, email, token, isSocialSignIn },
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const { mutate, isLoading, error } = useUpdateProfile(setSuccess);

  const onSubmit = (data: IFormData) => {
    const {
      username: newUserName,
      email: newUserEmail,
      password,
      avatar,
    } = data;

    const payload = {
      username: newUserName,
      email: newUserEmail,
      password,
      token,
    } as TUpdateProfileData;

    if (avatar && avatar instanceof FileList) {
      const file = avatar[0];
      payload.avatar = file;
    }

    if (!!newUserName && !!newUserEmail) {
      mutate(payload);
    }
  };

  const goToChangePassword = () => {
    setChangePassword(true);
  };

  const moduleClasses = classNames.bind(styles);
  const buttonClasses = moduleClasses('button', {
    'button-dark': !isLightMode,
  });

  const fields = useMemo(() => {
    if (isSocialSignIn) {
      const newFields = fieldsParameters.filter(
        (field) => field.type !== INPUT_TYPES.PASSWORD
      );

      return newFields;
    }
    return fieldsParameters;
  }, [isSocialSignIn]);

  const defaultValues = {
    username,
    email,
  } as IFormData;

  return (
    <Form
      fields={fields}
      btnTitle="Apply changes"
      onSubmit={onSubmit}
      validationSchema={validationSchemaChangeProfile}
      loading={isLoading}
      submitError={error}
      sign={
        !isSocialSignIn ? (
          <button
            type="button"
            onClick={goToChangePassword}
            className={buttonClasses}
          >
            Change password
          </button>
        ) : undefined
      }
      defaultValues={defaultValues}
      disableChanges={isSocialSignIn}
    />
  );
};

export default UpdateProfileForm;
