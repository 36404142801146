import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import history from 'helpers/history';

import routes from 'constants/routes';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { ACTION_TYPES } from 'context/modules/main/actions';

import { IParams, ICoverPicture } from 'types/models';

import DraftAlbumWatcher from 'watchers/draftAlbum';
import ErrorWatcher from 'watchers/scrollToError';

import useCancelAlbumCreation from 'hooks/api/useCancelAlbumCreation';
import useUpdateAlbum from 'hooks/api/useUpdateAlbum';
import useGetAlbum from 'hooks/api/useGetAlbum';
import useCheckUploadDetails from 'hooks/api/useCheckUploadDetails';

import Header from 'components/common/Header';
import MainContainer from 'components/common/MainContainer';
import EditAlbumSetting from 'components/common/EditAlbumSettings';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import AddPhotos from 'components/common/AddPhotos';

import styles from './index.module.scss';

const CreateAlbumSetting: React.FunctionComponent = () => {
  const [isSelectCoverMode, setChangeCoverMode] = useState<boolean>(false);
  const [oldCover, setOldCover] = useState<ICoverPicture>();
  const {
    state: {
      ui: { isLightMode },
      modalParameters,
    },
    uploadAlbum: { data, isCheckStatusEnabled, error },
    dispatchUploadAlbum,
    dispatch,
  } = useContext(AppContext);
  const { key: albumKey } = useParams<IParams>();

  const { hasNextPage, fetchNextPage } = useCheckUploadDetails();

  useEffect(() => {
    if (modalParameters.visible) {
      dispatch({
        type: ACTION_TYPES.SET_MODAL_PARAMETERS,
        payload: { ...modalParameters, newAlbumGalleryData: data?.images },
      });
    }
  }, [modalParameters.visible, data?.images?.length]);

  const { isLoading } = useGetAlbum({
    key: albumKey,
    options: {
      enabled: !data?.key && !modalParameters.visible,
      onSuccess: (response) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
          payload: response,
        });
      },
    },
  });

  useEffect(() => {
    setOldCover(data?.cover_picture);
  }, []);

  const { mutate: saveUpdates } = useUpdateAlbum();
  const cancel = useCancelAlbumCreation();

  const { cover_picture: mainPhoto, images, title } = data || {};

  const onCancel = () => {
    if (isSelectCoverMode) {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
        payload: { cover_picture: oldCover },
      });
      setChangeCoverMode(false);

      return;
    }

    cancel();
  };

  const save = () => {
    if (isSelectCoverMode) {
      if (data && data.cover_picture) {
        saveUpdates({ cover_picture: data.cover_picture });
      }

      setChangeCoverMode(false);
    } else {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
      });
      history.push(routes.MAIN);
    }
  };

  const setNewTitle = (newTitle: string): void => {
    if (data) {
      saveUpdates({ title: newTitle });
    }
  };

  const firstPhoto = images && images[0];
  const albumCover = mainPhoto || firstPhoto;

  const moduleClasses = classNames.bind(styles);
  const cancelClasses = moduleClasses('button', {
    'button-dark': !isLightMode,
  });
  const saveClasses = moduleClasses('save', 'button', {
    'button-dark': !isLightMode,
    'save-cover': isSelectCoverMode,
  });
  const wrapperClasses = moduleClasses('wrapper', {
    'wrapper-loading': isLoading,
  });
  const headingClasses = moduleClasses('heading', {
    'heading-cover': isSelectCoverMode,
  });

  const headingText = isSelectCoverMode ? 'Change album cover' : 'Create album';
  const saveButtonText = isSelectCoverMode ? 'Set cover' : 'Save';
  const isActionsDisabled = isLoading || !!isCheckStatusEnabled;
  const isInfiniteQueryAllowed = isCheckStatusEnabled ? false : !!hasNextPage;

  return (
    <MainContainer style={wrapperClasses}>
      <DraftAlbumWatcher />
      <ErrorWatcher />

      <Header
        leftButton={
          <Button
            classes={cancelClasses}
            title="Cancel"
            onClick={onCancel}
            disabled={isActionsDisabled}
          />
        }
        heading={headingText}
        rightButton={
          <Button
            classes={saveClasses}
            title={saveButtonText}
            onClick={save}
            disabled={isActionsDisabled}
          />
        }
        isShadowed
        fixedOnScroll={1}
        headingClasses={headingClasses}
      />

      {isLoading ? (
        <Spinner size={100} width={10} />
      ) : (
        <EditAlbumSetting
          error={error}
          photos={images}
          title={title}
          mainPhoto={albumCover}
          setNewTitle={setNewTitle}
          isSelectCoverMode={isSelectCoverMode}
          setChangeCoverMode={setChangeCoverMode}
          loading={isActionsDisabled}
          infiniteQueryOptions={{
            hasNextPage: isInfiniteQueryAllowed,
            fetchNextPage,
          }}
        />
      )}

      <AddPhotos
        isFixed
        isSelectCoverMode={isSelectCoverMode}
        save={save}
        isLoading={isLoading}
        disabledActions={isActionsDisabled}
      />
    </MainContainer>
  );
};

export default CreateAlbumSetting;
