/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-mutable-exports */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

interface IConfig {
  auth: firebase.auth.Auth;
  storage: firebase.storage.Storage;
}

const initFirebase = (): IConfig => {
  try {
    firebase.initializeApp(firebaseConfig);

    const auth: firebase.auth.Auth = firebase.auth();
    const storage: firebase.storage.Storage = firebase.storage();

    return { auth, storage };
  } catch (error) {
    throw new Error('Firebase credential are not correct');
  }
};

const config = initFirebase();

export default config;
