import React, { useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { ACTION_TYPES } from 'context/modules/main/actions';

import routes from 'constants/routes';
import {
  UPLOADED_PHOTOS_MAX_LENGTH,
  MAX_LENGTH_ERROR,
} from 'constants/constants';

import useSaveFilesFromFS from 'hooks/api/useSaveFilesFromFS';

import { googleUpload } from 'services/googlePicker';
import { DropboxFile, dropboxUpload } from 'services/dropboxChooser';

import photosImg from 'assets/images/modal-photos-collage.svg';
import dropboxImg from 'assets/images/dropbox.svg';
import googleDriveImg from 'assets/images/google-drive.svg';

import styles from './index.module.scss';

const ChoosePhotosModal: React.FunctionComponent = () => {
  const {
    dispatch,
    uploadAlbum: { data },
    dispatchUploadAlbum,
    state: {
      user: { token },
    },
  } = useContext(AppContext);
  const isCreateAlbumPage = useRouteMatch(routes.CREATE_ALBUM_PHOTOS);
  const isCreateAlbumSettingsPage = useRouteMatch(routes.CREATE_ALBUM_SETTINGS);
  const isEditAlbumPage = useRouteMatch(routes.EDIT_ALBUM_SETTINGS);
  const history = useHistory();

  const { mutate: saveFromFileSystem } = useSaveFilesFromFS();

  const closeModal = () => {
    dispatch({
      type: ACTION_TYPES.SET_MODAL_PARAMETERS,
      payload: { visible: false, content: null, isChoosePhotos: false },
    });
  };

  const redirect = () => {
    if (isEditAlbumPage || isCreateAlbumSettingsPage) return;

    if (!isCreateAlbumPage) {
      history.push(routes.CREATE_ALBUM_PHOTOS, {
        isAlbum: true,
      });
    }
  };

  const checkIsLargerMaxLength = (
    files: Array<DropboxFile> | FileList
  ): boolean => {
    const photosLength = data?.size ? data.size + files.length : files.length;
    const isLargerMaxLength = photosLength > UPLOADED_PHOTOS_MAX_LENGTH;

    if (isLargerMaxLength) {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
        payload: MAX_LENGTH_ERROR,
      });
    }

    return isLargerMaxLength;
  };

  const onFileSystemUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM,
    });

    closeModal();
    redirect();

    if (files) {
      const isError = checkIsLargerMaxLength(files);

      if (isError) return;

      saveFromFileSystem(files);
    }
  };

  const onGoogleUploadClick = () => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM,
    });

    if (token) {
      googleUpload(redirect, dispatchUploadAlbum, dispatch, token, data);
    } else {
      history.push(routes.SIGN_IN);
    }
    closeModal();
  };

  const onDropboxUploadClick = () => {
    const onGetFilesSuccess = (files: DropboxFile[]): boolean => {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM,
      });

      const isError = checkIsLargerMaxLength(files);

      closeModal();
      redirect();

      return isError;
    };

    if (token) {
      dropboxUpload(
        onGetFilesSuccess,
        dispatch,
        token,
        dispatchUploadAlbum,
        data
      );
    } else {
      history.push(routes.SIGN_IN);
      closeModal();
    }
  };

  return (
    <>
      <div className={styles.content}>
        <figure className={styles.choose}>
          <img src={photosImg} alt="collage" />
          <figcaption id="dialogTitle">Choose photos</figcaption>
        </figure>

        <button
          type="button"
          className={styles.service}
          onClick={onDropboxUploadClick}
        >
          <img src={dropboxImg} alt="dropbox" className={styles.service__img} />
          <p className={styles.service__caption}>
            Upload&nbsp;from
            <span className={styles['service__caption--blue']}>Dropbox</span>
          </p>
        </button>

        <button
          type="button"
          className={styles.service}
          onClick={onGoogleUploadClick}
        >
          <img
            src={googleDriveImg}
            alt="google drive"
            className={styles.service__img}
          />
          <p className={styles.service__caption}>
            Upload from
            <span className={styles['service__caption--blue']}>
              Google Drive
            </span>
          </p>
        </button>

        <p className={styles.computer}>
          or&nbsp;
          <label htmlFor="computer" className={styles.computer__label}>
            browse photos
            <input
              type="file"
              multiple
              accept=".png, .jpg, .jpeg"
              id="computer"
              className={styles.computer__input}
              onChange={onFileSystemUpload}
            />
          </label>
          &nbsp;on your computer
        </p>
      </div>
      <button type="button" onClick={closeModal} className={styles.cancel}>
        Cancel
      </button>
    </>
  );
};

export default ChoosePhotosModal;
