import React from 'react';
import FocusLock from 'react-focus-lock';

import styles from './index.module.scss';

interface IModalWrapper {
  children: React.ReactNode;
  classNames?: string;
}

const ModalWrapper: React.FunctionComponent<IModalWrapper> = ({
  children,
  classNames,
}) => {
  const classes = `${styles.wrapper} ${classNames || ''}`;

  return (
    <div className={classes}>
      <div className={styles.modal} role="dialog" aria-labelledby="dialogTitle">
        <FocusLock>{children}</FocusLock>
      </div>
    </div>
  );
};

export default ModalWrapper;
