import { COLORS } from 'constants/constants';
import React from 'react';

const LikeSVG: React.FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM12 8.3765C12.6125 7.5235 13.608 7 14.6905 7C16.515 7 18 8.48196 18 10.3035C18 13.544 13.0525 17.408 12.608 17.749C12.449 17.909 12.23 18 12 18C11.77 18 11.551 17.909 11.392 17.749C10.9475 17.408 6 13.544 6 10.3035C6 8.48196 7.48499 7 9.30954 7C10.392 7 11.3875 7.5235 12 8.3765Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM12 8.3765C12.6125 7.5235 13.608 7 14.6905 7C16.515 7 18 8.48196 18 10.3035C18 13.544 13.0525 17.408 12.608 17.749C12.449 17.909 12.23 18 12 18C11.77 18 11.551 17.909 11.392 17.749C10.9475 17.408 6 13.544 6 10.3035C6 8.48196 7.48499 7 9.30954 7C10.392 7 11.3875 7.5235 12 8.3765Z"
      fill={COLORS.GREY_1}
    />
    <path
      d="M12 8.3765L11.1877 8.95975L12 10.091L12.8123 8.95975L12 8.3765ZM12.608 17.749L11.9993 16.9556L11.9461 16.9964L11.8989 17.0439L12.608 17.749ZM11.392 17.749L12.1011 17.0439L12.0539 16.9964L12.0007 16.9556L11.392 17.749ZM12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20V22ZM2 12C2 17.5228 6.47715 22 12 22V20C7.58172 20 4 16.4183 4 12H2ZM12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4V2ZM22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12H22ZM12 24C18.6274 24 24 18.6274 24 12H22C22 17.5228 17.5228 22 12 22V24ZM0 12C0 18.6274 5.37258 24 12 24V22C6.47715 22 2 17.5228 2 12H0ZM12 0C5.37258 0 0 5.37258 0 12H2C2 6.47715 6.47715 2 12 2V0ZM24 12C24 5.37258 18.6274 0 12 0V2C17.5228 2 22 6.47715 22 12H24ZM14.6905 6C13.2831 6 11.9864 6.68095 11.1877 7.79324L12.8123 8.95975C13.2386 8.36605 13.933 8 14.6905 8V6ZM19 10.3035C19 7.9279 17.0655 6 14.6905 6V8C15.9645 8 17 9.03603 17 10.3035H19ZM13.2167 18.5424C13.4576 18.3576 14.8586 17.2651 16.2087 15.7883C16.8838 15.05 17.5679 14.192 18.0881 13.2824C18.6027 12.3824 19 11.3571 19 10.3035H17C17 10.8701 16.7788 11.543 16.3519 12.2896C15.9305 13.0265 15.3501 13.7635 14.7326 14.4388C13.4979 15.7894 12.2029 16.7994 11.9993 16.9556L13.2167 18.5424ZM12 19C12.4932 19 12.9683 18.8049 13.3171 18.4541L11.8989 17.0439C11.9296 17.013 11.9667 17 12 17V19ZM10.6829 18.4541C11.0317 18.8049 11.5068 19 12 19V17C12.0333 17 12.0704 17.013 12.1011 17.0439L10.6829 18.4541ZM5 10.3035C5 11.3571 5.39727 12.3824 5.9119 13.2824C6.43207 14.192 7.11625 15.05 7.79127 15.7883C9.14142 17.2651 10.5424 18.3576 10.7833 18.5424L12.0007 16.9556C11.7971 16.7994 10.5021 15.7894 9.26736 14.4388C8.64994 13.7635 8.06947 13.0265 7.64809 12.2896C7.22117 11.543 7 10.8701 7 10.3035H5ZM9.30954 6C6.93449 6 5 7.9279 5 10.3035H7C7 9.03603 8.03549 8 9.30954 8V6ZM12.8123 7.79324C12.0136 6.68095 10.7169 6 9.30954 6V8C10.067 8 10.7614 8.36605 11.1877 8.95975L12.8123 7.79324Z"
      fill={COLORS.GREY_1}
      mask="url(#path-1-inside-1)"
    />
  </svg>
);

export default LikeSVG;
