import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';

import routes from 'constants/routes';
import { AppContext } from 'context/AppProvider';
import UpdateProfile from 'components/UpdateProfile';
import ChangePassword from 'components/ChangePassword';
import Header from 'components/common/Header';
import MainContainer from 'components/common/MainContainer';

import ArrowBackSVG from 'assets/icons/arrow-back';

import styles from './index.module.scss';

const backLink = (
  <Link to={routes.MAIN} className={styles.back}>
    <ArrowBackSVG />
  </Link>
);

const Profile: React.FunctionComponent = () => {
  const [isUpdated, setSuccessfulUpdated] = useState<boolean>(false);
  const [isChangePassword, setChangePassword] = useState<boolean>(false);
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setSuccessfulUpdated(false);
  }, [pathname]);

  const moduleClasses = classNames.bind(styles);
  const successClasses = moduleClasses('success', {
    'success-dark': !isLightMode,
  });

  return (
    <MainContainer style={styles.main}>
      <Header
        leftButton={backLink}
        heading="Personal profile"
        isShadowed
        fixedOnScroll={1}
      />
      <main>
        {isUpdated && (
          <p className={successClasses}>Your changes have been saved.</p>
        )}

        {isChangePassword ? (
          <ChangePassword setSuccess={setSuccessfulUpdated} />
        ) : (
          <UpdateProfile
            setSuccess={setSuccessfulUpdated}
            setChangePassword={setChangePassword}
          />
        )}
      </main>
    </MainContainer>
  );
};

export default Profile;
