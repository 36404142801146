import React, { useMemo, useContext, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import classNames from 'classnames/bind';

import { IInfiniteQueryOptions } from 'types/models';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';

import MainContainer from 'components/common/MainContainer';

import GalleryArrowSVG from 'assets/icons/gallery-arrow';
import CloseSVG from 'assets/icons/close';

import styles from './index.module.scss';
import 'react-image-gallery/styles/css/image-gallery.css';

interface IData {
  startKey: string;
  infiniteQueryOptions?: IInfiniteQueryOptions;
}

const Gallery: React.FunctionComponent<IData> = ({
  startKey,
  infiniteQueryOptions,
}) => {
  const [index, setIndex] = useState<number>();
  const {
    dispatch,
    state: {
      modalParameters: { galleryData: data, newAlbumGalleryData },
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const imagesCount = useMemo(() => {
    const count = data && data.pages[0].count;
    const newAlbumCount = newAlbumGalleryData?.length;

    return count || newAlbumCount;
  }, [data, newAlbumGalleryData]);

  const galleryData = useMemo(() => {
    let images;
    let startIndex: number;

    if (data) {
      const results = data.pages.map((page) => page.results);

      images = results.flat(1).map(({ remote_url: remoteUrl, key }) => ({
        original: remoteUrl,
        key,
      }));
    }

    if (newAlbumGalleryData) {
      images = newAlbumGalleryData.map(({ remote_url: remoteUrl, key }) => ({
        original: remoteUrl,
        key,
      }));
    }

    if (images) {
      if (index) {
        startIndex = index;
      } else {
        startIndex = images.findIndex(({ key }) => key === startKey);
      }

      setIndex(startIndex);

      return { images, startIndex };
    }

    return null;
  }, [data?.pages.length, newAlbumGalleryData?.length]);

  const onBeforeSlide = (nextIndex: number) => {
    setIndex(nextIndex - 1);

    const isImages = galleryData?.images;
    const itemsLength = isImages && isImages.length;

    if (itemsLength) {
      const lastIndex = itemsLength - 1;

      const isPenultimateImage = nextIndex === lastIndex;
      const isPartialData = Number(itemsLength) !== Number(imagesCount);

      if (isPenultimateImage && isPartialData && infiniteQueryOptions) {
        infiniteQueryOptions.fetchNextPage();
      }
    }
  };

  const renderLeftNav = (
    onClick: React.MouseEventHandler<HTMLElement>,
    disabled: boolean
  ) => (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={styles.navigation}
    >
      <GalleryArrowSVG />
    </button>
  );

  const renderRightNav = (
    onClick: React.MouseEventHandler<HTMLElement>,
    disabled: boolean
  ) => (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={styles.navigation}
    >
      <GalleryArrowSVG reversed />
    </button>
  );

  const onClose = () => {
    dispatch({
      type: ACTION_TYPES.SET_MODAL_PARAMETERS,
      payload: {
        visible: false,
        content: null,
        wrapperStyles: '',
        newAlbumGalleryData: undefined,
      },
    });
  };

  const moduleClasses = classNames.bind(styles);
  const closeButtonClasses = moduleClasses('close', {
    'close-dark': !isLightMode,
  });

  return (
    <MainContainer style={styles.wrapper}>
      <button type="button" onClick={onClose} className={closeButtonClasses}>
        <CloseSVG />
      </button>

      {galleryData && (
        <ImageGallery
          items={galleryData.images}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          startIndex={galleryData.startIndex}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          infinite={false}
          onBeforeSlide={!newAlbumGalleryData ? onBeforeSlide : undefined}
          lazyLoad
        />
      )}
    </MainContainer>
  );
};

export default Gallery;
