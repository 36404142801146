import React, { useContext, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import { AppContext } from 'context/AppProvider';
import { IAlbumResponse } from 'types/models';
import { formatDate } from 'helpers/formatDate';
import Spinner from 'components/common/Spinner';

import PhotosCounterSVG from 'assets/icons/photos-counter';

import styles from './index.module.scss';

interface IAlbumsInfo {
  album?: IAlbumResponse;
  loading?: boolean;
}

const AlbumInfo: React.FunctionComponent<IAlbumsInfo> = ({
  album,
  loading,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);
  const { state } = useLocation<{ albumData?: IAlbumResponse }>();

  const {
    title,
    created_at: date,
    is_active: active,
    cover_picture: mainPhoto,
    size,
  } = state?.albumData || album || {};

  const formattedDate = useMemo((): string => {
    return date ? formatDate(date) : '';
  }, [date]);

  const moduleClasses = classNames.bind(styles);
  const contentClasses = moduleClasses('content', {
    'content-dark': !isLightMode,
  });
  const wrapperClasses = moduleClasses('wrapper', {
    'wrapper-loading': loading,
  });

  const { remote_url: mainPhotoUrl } = mainPhoto || {};
  const isAlbumInfo = state?.albumData || album;

  return (
    <section className={wrapperClasses}>
      {loading && !isAlbumInfo && <Spinner size={100} width={10} />}

      {isAlbumInfo && (
        <>
          <div className={styles.info}>
            <div className={contentClasses}>
              <p className={styles.content__date}>
                {formattedDate}
                &nbsp;
                {active && <span>/ active album</span>}
              </p>
              <h1 className={styles.content__title}>{title}</h1>
              <PhotosCounterSVG value={size || 0} />
            </div>
          </div>
          <div className={styles.image}>
            <img src={mainPhotoUrl} alt={title} className={styles.image__img} />
          </div>
        </>
      )}
    </section>
  );
};

export default AlbumInfo;
