import { useContext } from 'react';
import {
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from 'react-query';

import { AppContext } from 'context/AppProvider';
import { IAlbumResponse, TError } from 'types/models';
import { updateAlbum } from 'services/api/albums';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { QUERY_KEYS } from 'constants/constants';

interface IUpdateAlbum {
  mutate: UseMutateFunction<IAlbumResponse, TError, IAlbumResponse, unknown>;
  isLoading?: boolean;
}

export default (
  key?: string,
  options?: UseMutationOptions<IAlbumResponse, TError, IAlbumResponse>,
  onSuccessFn?: (response?: IAlbumResponse) => void,
  disableSaveState?: boolean
): IUpdateAlbum => {
  const {
    state: {
      user: { token },
    },
    dispatch,
    uploadAlbum: { data },
    dispatchUploadAlbum,
  } = useContext(AppContext);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<
    IAlbumResponse,
    TError,
    IAlbumResponse
  >((updates) => updateAlbum(dispatch, key || data?.key, updates, token), {
    onSuccess: (response) => {
      if (!disableSaveState) {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
          payload: response,
        });
      }

      queryClient.resetQueries(QUERY_KEYS.ALBUMS_LIST);

      if (onSuccessFn) onSuccessFn(response);
    },
    onError: (error) => {
      dispatchUploadAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
        payload: error.message,
      });
    },
    ...options,
  });

  return { mutate, isLoading };
};
