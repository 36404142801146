import React from 'react';

import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';

import styles from './index.module.scss';

interface IModalContent {
  title: string;
  subtitle: string;
  message?: string;
  leftButtonOptions: { text: string; onClick?: () => void };
  rightButtonOptions: { text: string; onClick?: () => void };
  isLoading?: boolean;
}

const ModalContent: React.FunctionComponent<IModalContent> = ({
  title,
  subtitle,
  message,
  leftButtonOptions,
  rightButtonOptions,
  isLoading,
}) => {
  const { text: leftButtonText, onClick: leftButtonOnClick } =
    leftButtonOptions;
  const { text: rightButtonText, onClick: rightButtonOnClick } =
    rightButtonOptions;

  const onClick = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id } = e?.currentTarget || {};

    if (id === 'left' && leftButtonOnClick) {
      leftButtonOnClick();
    } else if (id === 'right' && rightButtonOnClick) {
      rightButtonOnClick();
    }
  };

  return (
    <div className={styles.modal}>
      <h2 className={styles.modal__title}>{title}</h2>
      <h3 className={styles.modal__subtitle}>{subtitle}</h3>
      <h3 className={styles.modal__subtitle}>{message}</h3>

      <div className={styles.modal__buttons}>
        {isLoading ? (
          <Spinner disableModeSwitch size={50} />
        ) : (
          <>
            <Button
              onClick={onClick}
              title={leftButtonText}
              disableModeSwitch
              isMedium
              id="left"
            />
            <Button
              onClick={onClick}
              title={rightButtonText}
              disableModeSwitch
              isMedium
              id="right"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ModalContent;
