/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app';

import config from 'services/firebaseApi/config';
import {
  TSignUpUserDataWithEmail,
  TSignInUserDataWithEmail,
  TUpdateProfileData,
  TUpdateEmailResponse,
} from 'types/models';
import { ACTION_TYPES, TActions } from 'context/modules/main/actions';
import {
  uploadProfilePicture,
  updateProfileEmail,
} from 'services/api/updateProfile';

interface IUpdateProfilePayload {
  photoURL?: string;
  displayName: string;
}

const { auth } = config;

export const getCurrentUser = (): firebase.User | null => auth.currentUser;

const getToken = async (): Promise<string | undefined> => {
  const currentUser = getCurrentUser();
  const token = await currentUser?.getIdToken();
  return token;
};

export const onAuthStateChanged = async (
  dispatch: React.Dispatch<TActions>,
  setChecked: (state: boolean) => void
): Promise<void> => {
  auth.onAuthStateChanged(async (user): Promise<null> => {
    if (user) {
      const { displayName: username, email, uid, photoURL } = user;
      const token = await user.getIdToken();

      const isSocialSignIn = user.providerData.some(
        (providerData) =>
          providerData?.providerId ===
            firebase.auth.GoogleAuthProvider.PROVIDER_ID ||
          providerData?.providerId ===
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
      );

      dispatch({
        type: ACTION_TYPES.SET_USER,
        payload: {
          username,
          email,
          id: uid,
          photoURL,
          token,
          isSocialSignIn,
        },
      });
    }

    setChecked(true);

    return null;
  });
};

export const signUpWithEmail = async (
  userData: TSignUpUserDataWithEmail
): Promise<string | undefined> => {
  try {
    const { email, password, username } = userData;

    await auth.createUserWithEmailAndPassword(email, password);

    const currentUser = getCurrentUser();

    await currentUser?.updateProfile({ displayName: username });
    const token = await getToken();

    return token;
  } catch (error) {
    throw Error(error);
  }
};

export const signInWithEmail = async (
  userData: TSignInUserDataWithEmail
): Promise<string | undefined> => {
  try {
    const { email, password } = userData;

    await auth.signInWithEmailAndPassword(email, password);
    const token = await getToken();

    return token;
  } catch (error) {
    throw Error(error);
  }
};

export const resetPassword = (email: string): Promise<void> =>
  auth.sendPasswordResetEmail(email);

export const logOut = (): Promise<void> => auth.signOut();

const reAuthenticate = async (
  password: string
): Promise<firebase.auth.UserCredential | null | undefined> => {
  try {
    const currentUser = getCurrentUser();

    if (currentUser?.email) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser?.email,
        password
      );

      return currentUser?.reauthenticateWithCredential(credential);
    }

    return null;
  } catch (error) {
    throw Error(error);
  }
};

export const changePassword = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  try {
    const currentUser = getCurrentUser();
    await reAuthenticate(currentPassword);

    return currentUser?.updatePassword(newPassword);
  } catch (error) {
    throw Error(error);
  }
};

export const updateProfile = async (
  dispatch: React.Dispatch<TActions>,
  data: TUpdateProfileData
): Promise<TUpdateEmailResponse> => {
  try {
    const { password, username: displayName, email, avatar, token } = data;

    const updates = { displayName } as IUpdateProfilePayload;

    if (avatar && avatar instanceof File) {
      const newAvatarUrl = await uploadProfilePicture(dispatch, avatar, token);
      updates.photoURL = newAvatarUrl;
    }

    await reAuthenticate(password);
    await auth.currentUser?.updateProfile(updates);

    const updateUserEmail = await updateProfileEmail(dispatch, email, token);

    return updateUserEmail;
  } catch (error) {
    throw Error(error);
  }
};

const signInViaSocial = async (
  provider:
    | firebase.auth.GoogleAuthProvider
    | firebase.auth.FacebookAuthProvider
): Promise<string | undefined> => {
  await auth.signInWithPopup(provider);
  const token = await getToken();

  return token;
};

export const googleSignIn = async (): Promise<string | undefined> => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    const token = await signInViaSocial(provider);

    return token;
  } catch (error) {
    throw Error(error);
  }
};

export const facebookSignIn = async (): Promise<string | undefined> => {
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    const token = await signInViaSocial(provider);

    return token;
  } catch (error) {
    throw Error(error);
  }
};
