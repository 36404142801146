import React, { useContext } from 'react';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { ACTION_TYPES } from 'context/modules/main/actions';

import { IAlbumResponse, TError } from 'types/models';

import history from 'helpers/history';

import routes from 'constants/routes';
import { QUERY_KEYS } from 'constants/constants';

import { deleteImage } from 'services/api/images';
import { deleteAlbum } from 'services/api/albums';

import ModalContent from 'components/common/ModalContent';

interface IDeletePhoto {
  mutate: UseMutateFunction<IAlbumResponse | void, TError>;
  isLoading?: boolean;
}

export default (key: string): IDeletePhoto => {
  const {
    dispatchUploadAlbum,
    dispatch,
    state: {
      user: { token },
    },
    uploadAlbum: { data },
  } = useContext(AppContext);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<IAlbumResponse | void, TError>(
    () => deleteImage(dispatch, dispatchUploadAlbum, key, token, data),
    {
      onSuccess: (response) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.DELETE_UPLOADED_PHOTO,
          payload: key,
        });

        if (response) {
          dispatchUploadAlbum({
            type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
            payload: response,
          });
        }

        if (data?.key) {
          const oldData: IAlbumResponse | undefined = queryClient.getQueryData(
            data.key
          );

          if (oldData) {
            const newImages = oldData.images?.filter(
              (image) => image.key !== key
            );

            const newData = response || oldData;

            queryClient.setQueryData(data.key, {
              ...newData,
              size: newImages?.length,
              images: newImages,
            });
          }
        }
      },
      onError: (error) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
          payload: error.message,
        });
      },
    }
  );

  const { mutate: cancelAlbum } = useMutation<void, TError>(
    () => deleteAlbum(dispatch, data?.key, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.ALBUMS_LIST);

        history.push(routes.MAIN);

        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
        });
      },
      onError: (err) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
          payload: err.message,
        });
      },
      onSettled: () => {
        dispatch({
          type: ACTION_TYPES.SET_MODAL_PARAMETERS,
          payload: {
            visible: false,
            isChoosePhotos: false,
            content: null,
          },
        });
      },
    }
  );

  const deleteImg = () => {
    if (data?.size === 1) {
      const onDeleteAlbum = () => {
        cancelAlbum();
      };

      const cancel = () => {
        dispatch({
          type: ACTION_TYPES.SET_MODAL_PARAMETERS,
          payload: { visible: false, isChoosePhotos: false, content: null },
        });
      };

      dispatch({
        type: ACTION_TYPES.SET_MODAL_PARAMETERS,
        payload: {
          visible: true,
          content: (
            <ModalContent
              title="Delete album?"
              subtitle="By deleting last image in album, you will delete album too"
              leftButtonOptions={{
                text: 'Delete',
                onClick: onDeleteAlbum,
              }}
              rightButtonOptions={{
                text: 'Cancel',
                onClick: cancel,
              }}
              isLoading={isLoading}
            />
          ),
        },
      });
    } else {
      mutate();
    }
  };

  return { mutate: deleteImg, isLoading };
};
