import { IUISettings, IAlbumResponse } from 'types/models';

type TLocalStorage = IUISettings | IAlbumResponse;

export const getFromStorage = (key: string): TLocalStorage | null => {
  const dataString = localStorage.getItem(key);

  if (dataString) {
    const newData = JSON.parse(dataString);
    return newData;
  }

  return null;
};

export const saveToStorage = (key: string, value: TLocalStorage): void => {
  const dataString = JSON.stringify(value);
  localStorage.setItem(key, dataString);
};

export const removeFromStorage = (key: string): void => {
  localStorage.removeItem(key);
};
