import { useContext } from 'react';
import { useMutation, useQueryClient, UseMutateFunction } from 'react-query';

import routes from 'constants/routes';
import history from 'helpers/history';
import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';
import { logOut } from 'services/firebaseApi/auth';
import { IUser } from 'types/models';

interface ILogOut {
  mutate: UseMutateFunction;
  isLoading?: boolean;
}

export default (): ILogOut => {
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(() => logOut(), {
    onSuccess: () => {
      dispatch({
        type: ACTION_TYPES.SET_USER,
        payload: {} as IUser,
      });

      queryClient.clear();
      history.push(routes.SIGN_IN);
    },
  });

  return { mutate, isLoading };
};
