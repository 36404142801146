import axios from 'axios';

import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from 'helpers/axios';
import history from 'helpers/history';
import { IAlbumResponse } from 'types/models';
import {
  DEFAULT_TITLE,
  URLS,
  CHECK_STATUS_CONDITIONS,
  API_URL,
} from 'constants/constants';
import { TActions } from 'context/modules/main/actions';
import {
  TUploadAlbumActions,
  UPLOAD_ALBUM_ACTION_TYPES,
} from 'context/modules/uploadAlbum/actions';
import routes from 'constants/routes';

export const getAlbums = async (
  dispatch: React.Dispatch<TActions>,
  token?: string
): Promise<Array<IAlbumResponse>> => {
  try {
    const result = await authorizedGetRequest<Array<IAlbumResponse>>({
      token,
      dispatch,
      path: `${URLS.ALBUMS}${URLS.LIST}/`,
    });

    return result;
  } catch (error) {
    throw Error(error);
  }
};

export const getAlbum = async (
  dispatch: React.Dispatch<TActions>,
  albumKey?: string,
  token?: string,
  isPublic = false
): Promise<IAlbumResponse> => {
  try {
    const url = `${
      isPublic ? `${API_URL}${URLS.ALBUMS}${URLS.PUBLIC}` : `${URLS.ALBUMS}`
    }/${albumKey}/`;

    if (!isPublic) {
      const data = await authorizedGetRequest<IAlbumResponse>({
        token,
        dispatch,
        path: url,
      });

      return data;
    }

    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

export const updateAlbum = async (
  dispatch: React.Dispatch<TActions>,
  albumKey?: string,
  album?: IAlbumResponse,
  token?: string
): Promise<IAlbumResponse> => {
  try {
    if (albumKey) {
      const data = await authorizedPutRequest<IAlbumResponse>({
        token,
        dispatch,
        path: `${URLS.ALBUMS}/${albumKey}${URLS.UPDATE}/`,
        body: { ...album, cover_picture: album?.cover_picture?.key },
      });

      return data;
    }

    if (!albumKey) {
      history.push(routes.MAIN);
    }

    throw Error('[update album]: album key is missing');
  } catch (error) {
    throw Error(error.message);
  }
};

export const createAlbum = async (
  newFilesLength: number,
  dispatch: React.Dispatch<TActions>,
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>,
  token?: string,
  album?: IAlbumResponse
): Promise<IAlbumResponse> => {
  try {
    let currentAlbum = { ...album };

    if (!currentAlbum.key) {
      const data = await authorizedPostRequest<IAlbumResponse>({
        token,
        dispatch,
        path: `${URLS.ALBUMS}${URLS.CREATE}/`,
        body: { title: DEFAULT_TITLE },
      });

      currentAlbum = data;
    }

    if (currentAlbum.key) {
      const size = currentAlbum.size
        ? currentAlbum.size + newFilesLength
        : newFilesLength;

      currentAlbum = await updateAlbum(
        dispatch,
        currentAlbum.key,
        { size },
        token
      );
    }

    dispatchAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_ALBUM_INSTANCE,
      payload: currentAlbum,
    });

    return currentAlbum;
  } catch (error) {
    throw Error(error);
  }
};

export const confirmCreation = async (
  dispatch: React.Dispatch<TActions>,
  album?: IAlbumResponse,
  token?: string
): Promise<IAlbumResponse> => {
  try {
    if (album) {
      await authorizedPutRequest({
        token,
        dispatch,
        path: `${URLS.ALBUMS}/${album.key}${URLS.CONFIRM}/`,
      });

      const { images } = album || {};
      const updates = { cover_picture: images ? images[0] : undefined };

      const updated = await updateAlbum(dispatch, album.key, updates, token);

      return updated;
    }
    if (!album) {
      history.push(routes.MAIN);
    }

    throw Error('[confirm creation]: album is missing');
  } catch (error) {
    throw Error(error.message);
  }
};

export const deleteAlbum = async (
  dispatch: React.Dispatch<TActions>,
  albumKey?: string,
  token?: string
): Promise<void> => {
  try {
    if (albumKey) {
      await authorizedDeleteRequest({
        token,
        dispatch,
        path: `${URLS.ALBUMS}/${albumKey}${URLS.DELETE}/`,
      });

      return;
    }

    throw Error('[delete album]: album key is missing');
  } catch (error) {
    throw Error(error.message);
  }
};

export const checkDraftAlbum = async (
  dispatch: React.Dispatch<TActions>,
  token?: string
): Promise<IAlbumResponse | void> => {
  try {
    const data = await authorizedGetRequest<IAlbumResponse>({
      token,
      dispatch,
      path: `${URLS.ALBUMS}/check-draft/`,
    });

    return data;
  } catch {
    return undefined;
  }
};

export const submitAlbum = async (
  desiredSize: number,
  dispatch: React.Dispatch<TActions>,
  dispatchAlbum: React.Dispatch<TUploadAlbumActions>,
  token?: string,
  albumKey?: string
): Promise<IAlbumResponse> => {
  try {
    if (albumKey) {
      await updateAlbum(
        dispatch,
        albumKey,
        { desired_size: desiredSize },
        token
      );

      const data = await authorizedPutRequest<IAlbumResponse>({
        token,
        dispatch,
        path: `${URLS.ALBUMS}/${albumKey}/submit/`,
      });

      dispatchAlbum({
        type: UPLOAD_ALBUM_ACTION_TYPES.SET_CHECK_STATUS_ENABLED,
        payload: CHECK_STATUS_CONDITIONS.PROCESSING,
      });

      return data;
    }

    if (!albumKey) {
      history.push(routes.MAIN);
    }
    throw Error('[submit album]: album key is missing');
  } catch (error) {
    throw Error(error.message);
  }
};

export const getAlbumShareLink = async (
  dispatch: React.Dispatch<TActions>,
  token?: string,
  albumKey?: string
): Promise<string> => {
  try {
    if (albumKey) {
      // eslint-disable-next-line camelcase
      const data = await authorizedGetRequest<{ public_link: string }>({
        token,
        dispatch,
        path: `${URLS.ALBUMS}/${albumKey}/public-link/`,
      });

      return data.public_link;
    }

    throw Error('[get share link]: album key is missing');
  } catch (error) {
    throw Error(error.message);
  }
};
