import React, { useContext } from 'react';
import { useMutation } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { ACTION_TYPES } from 'context/modules/main/actions';

import routes from 'constants/routes';

import history from 'helpers/history';

import ModalContent from 'components/common/ModalContent';

import { deleteAlbum } from 'services/api/albums';

import { TError } from 'types/models';

export default (): (() => void) => {
  const {
    dispatch,
    dispatchUploadAlbum,
    state: {
      user: { token },
    },
    uploadAlbum: { data },
  } = useContext(AppContext);

  const { mutate: cancelAlbum, isLoading } = useMutation<void, TError>(
    () => deleteAlbum(dispatch, data?.key, token),
    {
      onSuccess: () => {
        history.push(routes.MAIN);

        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.CANCEL_UPLOAD_ALBUM,
        });
      },
      onError: (err) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
          payload: err.message,
        });
      },
      onSettled: () => {
        dispatch({
          type: ACTION_TYPES.SET_MODAL_PARAMETERS,
          payload: {
            visible: false,
            isChoosePhotos: false,
            content: null,
          },
        });
      },
    }
  );

  const cancelModal = () => {
    const onDeleteAlbum = () => {
      cancelAlbum();
    };

    const continueAlbum = () => {
      dispatch({
        type: ACTION_TYPES.SET_MODAL_PARAMETERS,
        payload: { visible: false, isChoosePhotos: false, content: null },
      });
    };

    dispatch({
      type: ACTION_TYPES.SET_MODAL_PARAMETERS,
      payload: {
        visible: true,
        content: (
          <ModalContent
            title="Delete album"
            subtitle="Are you sure you want to discard album creation?"
            message="All changes will be lost."
            leftButtonOptions={{
              text: 'Delete',
              onClick: onDeleteAlbum,
            }}
            rightButtonOptions={{
              text: 'Cancel',
              onClick: continueAlbum,
            }}
            isLoading={isLoading}
          />
        ),
      },
    });
  };

  return cancelModal;
};
