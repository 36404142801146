import React, { useContext, useMemo } from 'react';

import { COLORS } from 'constants/constants';
import { IIconProps } from 'types/models';
import { AppContext } from 'context/AppProvider';

const LockSVG: React.FunctionComponent<IIconProps> = ({
  isDark,
  disableModeSwitch,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = useMemo((): string => {
    if (!disableModeSwitch && !isLightMode) {
      return isDark ? COLORS.WHITE : COLORS.GREY;
    }

    return isDark ? COLORS.BLACK : COLORS.GREY;
  }, [isDark, isLightMode, disableModeSwitch]);

  return (
    <svg
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.50034 7.97447H2.60034V7.87447V5.25107C2.60034 3.88357 3.11709 2.5726 4.03654 1.60711C4.95587 0.641751 6.20211 0.1 7.5 0.1C8.79787 0.1 10.0431 0.641734 10.9624 1.60711C11.8819 2.57264 12.3997 3.88362 12.3997 5.25107V7.87447V7.97447H12.4997H13.1242C13.5934 7.97447 14.045 8.17027 14.3783 8.52023C14.7117 8.87036 14.9 9.34695 14.9 9.84415V19.0325C14.9 19.5295 14.7118 20.004 14.3783 20.3542C14.045 20.7042 13.5934 20.9 13.1242 20.9H1.87475C1.40566 20.9 0.955033 20.7042 0.621718 20.3542C0.28821 20.004 0.1 19.5295 0.1 19.0325V9.84415C0.1 9.34695 0.288285 8.87036 0.621718 8.52023C0.955032 8.17023 1.40566 7.97447 1.87475 7.97447H2.50034ZM10.6249 7.97447H10.7249V7.87447V5.25107C10.7249 4.35584 10.3861 3.49442 9.78183 2.85993C9.17736 2.22519 8.35678 1.86968 7.5 1.86968C6.64322 1.86968 5.82264 2.22519 5.21817 2.85993C4.61393 3.49442 4.27508 4.35584 4.27508 5.25107V7.87447V7.97447H4.37508H10.6249ZM1.77475 18.5325C1.77475 18.8638 2.04337 19.1325 2.37475 19.1325H12.6242C12.9556 19.1325 13.2242 18.8638 13.2242 18.5325V10.3442C13.2242 10.0128 12.9556 9.74415 12.6242 9.74415H2.37475C2.04338 9.74415 1.77475 10.0128 1.77475 10.3442V18.5325Z"
        fill={color}
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default LockSVG;
