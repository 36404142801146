import React, { useContext } from 'react';
import { useMutation } from 'react-query';

import Button from 'components/common/Button';
import { AppContext } from 'context/AppProvider';
import {
  googleSignIn,
  getCurrentUser,
  facebookSignIn,
} from 'services/firebaseApi/auth';
import { ACTION_TYPES } from 'context/modules/main/actions';
import history from 'helpers/history';
import routes from 'constants/routes';
import { TError } from 'types/models';

import facebookImg from 'assets/images/facebook.svg';
import googleImg from 'assets/images/google-plus.svg';

import styles from './index.module.scss';

interface ISocialSignIn {
  setSocialError: (error: TError) => void;
}

const SocialSignIn: React.FunctionComponent<ISocialSignIn> = ({
  setSocialError,
}) => {
  const { dispatch } = useContext(AppContext);

  const onLogInSuccess = (token: string) => {
    const currentUser = getCurrentUser();

    if (currentUser) {
      const { uid, email, displayName, photoURL } = currentUser;

      dispatch({
        type: ACTION_TYPES.SET_USER,
        payload: {
          id: uid,
          email,
          username: displayName,
          photoURL,
          token,
          isSocialSignIn: true,
        },
      });

      history.push(routes.MAIN);
    }
  };

  const { mutate: googleLogIn } = useMutation<undefined | string, TError>(
    googleSignIn,
    {
      onSuccess: (token) => {
        if (token) {
          onLogInSuccess(token);
        }
      },
      onError: (error) => {
        setSocialError(error);
      },
    }
  );

  const { mutate: facebookLogIn } = useMutation<undefined | string, TError>(
    facebookSignIn,
    {
      onSuccess: (token) => {
        if (token) {
          onLogInSuccess(token);
        }
      },
      onError: (error) => {
        setSocialError(error);
      },
    }
  );

  return (
    <div className={styles.social}>
      <Button
        title="Facebook"
        icon={facebookImg}
        disableModeSwitch
        onClick={facebookLogIn}
      />
      <Button
        title="Google"
        icon={googleImg}
        disableModeSwitch
        onClick={googleLogIn}
      />
    </div>
  );
};

export default SocialSignIn;
