import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';

import { IUpdateProfileProps } from 'types/models';

import useLogout from 'hooks/api/useLogout';

import Container from 'components/common/Container';
import Spinner from 'components/common/Spinner';
import UpdateProfileForm from 'components/UpdateProfileForm';

import LogoutSVG from 'assets/icons/logout';

import styles from './index.module.scss';

const UpdateProfile: React.FunctionComponent<IUpdateProfileProps> = ({
  setSuccess,
  setChangePassword,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const { mutate, isLoading } = useLogout();

  const logOutHandler = () => {
    mutate();
  };

  const moduleClasses = classNames.bind(styles);
  const logoutClasses = moduleClasses('logout', {
    'logout-dark': !isLightMode,
  });

  return (
    <Container className={styles.container}>
      <UpdateProfileForm
        setSuccess={setSuccess}
        setChangePassword={setChangePassword}
      />

      <div className={styles.additional}>
        <button
          type="button"
          className={logoutClasses}
          onClick={logOutHandler}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={23} width={4} /> : <LogoutSVG />}
          Log out
        </button>
      </div>
    </Container>
  );
};

export default UpdateProfile;
