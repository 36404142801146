import axios, { AxiosInstance, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import React from 'react';
import jwtDecode from 'jwt-decode';

import { getCurrentUser } from 'services/firebaseApi/auth';

import { API_URL } from 'constants/constants';
import routes from 'constants/routes';

import { ACTION_TYPES, TActions } from 'context/modules/main/actions';

import history from 'helpers/history';

const checkIfExpired = (token: string) => {
  const decodedToken = jwtDecode<{ exp: number }>(token);
  const expiresInMs = new Date().getTime() - decodedToken.exp * 1000;

  return expiresInMs >= 0;
};

const getValidToken = async (
  token: string,
  dispatch: React.Dispatch<TActions>
): Promise<string> => {
  try {
    const isExpired = checkIfExpired(token);

    if (!isExpired) {
      return `Bearer ${token}`;
    }

    const user = getCurrentUser();

    if (user) {
      const newToken = await user.getIdToken(true);
      dispatch({
        type: ACTION_TYPES.SET_USER_TOKEN,
        payload: newToken,
      });

      return `Bearer ${newToken}`;
    }

    throw Error('Invalid token');
  } catch (error) {
    throw Error(error);
  }
};

const customAxios = (
  token: string,
  dispatch: React.Dispatch<TActions>
): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: API_URL,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const newConfig = { ...config };

      const bearerToken = await getValidToken(token, dispatch);
      newConfig.headers.authorization = bearerToken;

      return newConfig;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

export const axiosRetryInstance = (
  token: string,
  dispatch: React.Dispatch<TActions>,
  retryCondition?: (e: AxiosError) => boolean
): AxiosInstance => {
  const axiosInstance = customAxios(token, dispatch);

  axiosRetry(axiosInstance, {
    retryDelay: axiosRetry.exponentialDelay,
    retries: 3,
    retryCondition,
  });

  return axiosInstance;
};

export const authorizedGetRequest = async <ReturnType>({
  dispatch,
  token,
  path,
  baseURL,
}: {
  dispatch: React.Dispatch<TActions>;
  token?: string;
  path: string;
  baseURL?: string;
}): Promise<ReturnType> => {
  try {
    if (!token) {
      history.push(routes.SIGN_IN);
      throw Error('[get request]: token is missing');
    }

    const { data } = await customAxios(token, dispatch).get<ReturnType>(path, {
      baseURL,
    });

    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const authorizedPostRequest = async <ReturnType>({
  path,
  token,
  dispatch,
  body,
  retryCondition,
}: {
  path: string;
  token?: string;
  dispatch: React.Dispatch<TActions>;
  body: unknown;
  retryCondition?: (e: AxiosError) => boolean;
}): Promise<ReturnType> => {
  try {
    if (!token) {
      history.push(routes.SIGN_IN);

      throw Error('[post request]: token is missing');
    }

    const axiosInstance = retryCondition ? axiosRetryInstance : customAxios;

    const { data } = await axiosInstance(
      token,
      dispatch,
      retryCondition
    ).post<ReturnType>(path, body);

    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const authorizedPutRequest = async <ReturnType>({
  path,
  token,
  dispatch,
  body,
}: {
  path: string;
  token?: string;
  dispatch: React.Dispatch<TActions>;
  body?: unknown;
}): Promise<ReturnType> => {
  try {
    if (!token) {
      history.push(routes.SIGN_IN);

      throw Error('[put request]: token is missing');
    }

    const { data } = await customAxios(token, dispatch).put<ReturnType>(
      path,
      body
    );

    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const authorizedDeleteRequest = async ({
  token,
  dispatch,
  path,
}: {
  token?: string;
  dispatch: React.Dispatch<TActions>;
  path: string;
}): Promise<void> => {
  try {
    if (!token) {
      history.push(routes.SIGN_IN);

      throw Error('[delete request]: token is missing');
    }

    await customAxios(token, dispatch).delete(path);
  } catch (error) {
    throw Error(error);
  }
};
