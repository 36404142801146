import React, { useState, useContext } from 'react';
import classNames from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ICoverPicture, IInfiniteQueryOptions, TNewPhoto } from 'types/models';
import { AppContext } from 'context/AppProvider';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import { DEFAULT_TITLE } from 'constants/constants';

import AddPhotos from 'components/common/AddPhotos';
import EditPhotoList from 'components/common/EditPhotosList';
import Container from 'components/common/Container';
import Error from 'components/common/Error';
import Spinner from 'components/common/Spinner';

import styles from './index.module.scss';

interface IEditAlbumSetting {
  photos?: TNewPhoto[];
  mainPhoto?: ICoverPicture;
  setNewCover?: (photo: TNewPhoto) => void;
  title?: string;
  setNewTitle?: (title: string) => void;
  isSelectCoverMode: boolean;
  setChangeCoverMode: (state: boolean) => void;
  loading?: boolean;
  error?: string;
  resetError?: () => void;
  infiniteQueryOptions?: IInfiniteQueryOptions;
}

const EditAlbumSetting: React.FunctionComponent<IEditAlbumSetting> = ({
  photos,
  mainPhoto,
  setNewCover,
  title,
  setNewTitle,
  isSelectCoverMode,
  setChangeCoverMode,
  loading,
  error,
  resetError,
  infiniteQueryOptions,
}) => {
  const [albumTitle, setTitle] = useState<string>(title || 'Add title');

  const {
    state: {
      ui: { isLightMode },
    },
    dispatchUploadAlbum,
  } = useContext(AppContext);

  const onSelectCoverMode = () => {
    setChangeCoverMode(true);
  };

  const setNewAlbumCover = (photo: TNewPhoto) => {
    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
      payload: { cover_picture: photo },
    });

    if (setNewCover) {
      setNewCover(photo);
    }
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitle(value);
  };

  const onInputBlur = () => {
    const isEmptyTitle = albumTitle.trim() === '';
    if (isEmptyTitle) {
      if (setNewTitle) {
        setNewTitle(DEFAULT_TITLE);
      }
      setTitle(DEFAULT_TITLE);

      return;
    }

    dispatchUploadAlbum({
      type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
      payload: { title: albumTitle },
    });

    if (setNewTitle) {
      setNewTitle(albumTitle);
    }
  };

  const moduleClasses = classNames.bind(styles);
  const inputClasses = moduleClasses('settings__input', {
    'settings__input--dark': !isLightMode,
  });
  const coverClasses = moduleClasses('settings__img', {
    'settings__img--dark': !isLightMode && isSelectCoverMode,
    'settings__img--light': isLightMode && isSelectCoverMode,
  });
  const { hasNextPage, fetchNextPage } = infiniteQueryOptions || {};

  const nextPage = () => {
    if (fetchNextPage) {
      fetchNextPage();
    }
  };

  return (
    <main>
      <Container className={styles.container}>
        <div className={`${styles.error} ${styles.error__big}`}>
          <Error message={error || ''} />
        </div>

        <section className={styles.settings}>
          <div className={styles.settings__title}>
            <input
              type="text"
              value={albumTitle}
              onChange={inputHandler}
              onBlur={onInputBlur}
              className={inputClasses}
            />
          </div>
          <div className={styles.settings__cover}>
            <div className={coverClasses}>
              {mainPhoto?.remote_url && (
                <img src={mainPhoto.remote_url} alt="Main" />
              )}
              <button
                type="button"
                className={styles.settings__change}
                onClick={onSelectCoverMode}
              >
                Change album cover
              </button>
            </div>
          </div>
        </section>

        <div className={`${styles.error} ${styles.error__small}`}>
          <Error message={error || ''} />
        </div>

        <AddPhotos loading={loading} />

        {infiniteQueryOptions ? (
          <InfiniteScroll
            hasMore={!!hasNextPage}
            next={nextPage}
            style={{ overflowY: 'hidden' }}
            dataLength={Number(photos?.length)}
            loader={<Spinner size={80} width={8} />}
          >
            <EditPhotoList
              photos={photos}
              mainPhoto={mainPhoto}
              setNewCover={isSelectCoverMode ? setNewAlbumCover : undefined}
              resetError={resetError}
              disabledActions={loading}
            />
          </InfiniteScroll>
        ) : (
          <EditPhotoList
            photos={photos}
            mainPhoto={mainPhoto}
            setNewCover={isSelectCoverMode ? setNewAlbumCover : undefined}
            resetError={resetError}
            disabledActions={loading}
          />
        )}
      </Container>
    </main>
  );
};

export default EditAlbumSetting;
