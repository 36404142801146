import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

interface IArrowBackSVG {
  isDark?: boolean;
}

const ArrowBackSVG: React.FunctionComponent<IArrowBackSVG> = ({ isDark }) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const modeColor = isLightMode ? COLORS.BLACK : COLORS.WHITE;
  const color = isDark ? COLORS.BLACK : modeColor;

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.51378 11.5001C5.51378 11.8764 5.65749 12.2527 5.94429 12.5397L14.9739 21.5692C15.5483 22.1436 16.4796 22.1436 17.0538 21.5692C17.6279 20.995 17.6279 20.0639 17.0538 19.4895L9.06384 11.5001L17.0535 3.51057C17.6276 2.93617 17.6276 2.00517 17.0535 1.43105C16.4793 0.856379 15.548 0.856379 14.9736 1.43105L5.94401 10.4604C5.65716 10.7475 5.51378 11.1238 5.51378 11.5001Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowBackSVG;
