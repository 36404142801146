import { useContext } from 'react';
import { useMutation, UseMutateFunction } from 'react-query';

import { ACTION_TYPES } from 'context/modules/main/actions';
import { updateProfile, getCurrentUser } from 'services/firebaseApi/auth';
import { TUpdateProfileData, TUpdateEmailResponse, TError } from 'types/models';
import { AppContext } from 'context/AppProvider';

interface IUpdateProfile {
  mutate: UseMutateFunction<TUpdateEmailResponse, TError, TUpdateProfileData>;
  isLoading?: boolean;
  error: TError | null;
}

export default (setSuccess: (isSuccess: boolean) => void): IUpdateProfile => {
  const { dispatch } = useContext(AppContext);

  const { mutate, isLoading, error } = useMutation<
    TUpdateEmailResponse,
    TError,
    TUpdateProfileData
  >((userData) => updateProfile(dispatch, userData), {
    onSuccess: async () => {
      const user = getCurrentUser();

      if (user) {
        const {
          displayName,
          email: newEmail,
          photoURL: newPhotoURL,
          uid,
        } = user;

        const token = await user.getIdToken();

        dispatch({
          type: ACTION_TYPES.SET_USER,
          payload: {
            id: uid,
            username: displayName,
            email: newEmail,
            photoURL: newPhotoURL,
            isSocialSignIn: false,
            token,
          },
        });
        setSuccess(true);
      }
    },
  });

  return { mutate, isLoading, error };
};
