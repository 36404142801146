import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import useCheckDraftAlbum from 'hooks/api/useCheckDraftAlbum';
import { AppContext } from 'context/AppProvider';

const DraftAlbumWatcher: React.FunctionComponent = () => {
  const { setEnabled } = useCheckDraftAlbum();
  const { uploadAlbum } = useContext(AppContext);
  const { state } = useLocation();

  useEffect(() => {
    const isAlbumExists = !!uploadAlbum.data?.key;
    if (!state) setEnabled(!isAlbumExists);
  }, []);

  return null;
};

export default DraftAlbumWatcher;
