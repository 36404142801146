import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

const Plus: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const fill = isLightMode ? COLORS.BLACK : COLORS.WHITE;

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.1756 17.4123C5.17506 17.8041 5.33444 18.1592 5.5909 18.4157C5.84748 18.6723 6.20251 18.8315 6.59433 18.831L15.8177 18.8308L15.8175 28.0542C15.8171 28.4461 15.9764 28.8011 16.2328 29.0576C16.4891 29.3139 16.8441 29.4732 17.2362 29.4729C18.0199 29.4729 18.655 28.8378 18.655 28.0542L18.6547 18.8307L27.4051 18.8307C28.1887 18.831 28.8241 18.1956 28.8238 17.412C28.8241 16.6284 28.189 15.9933 27.4051 15.9933L18.655 15.9933L18.6552 7.24316C18.6554 6.45939 18.0201 5.82417 17.2366 5.82458C16.453 5.8243 15.8177 6.45967 15.8179 7.2433L15.8178 15.9935L6.59474 15.9937C5.81083 15.9931 5.17574 16.6282 5.1756 17.4123Z"
        fill={fill}
      />
    </svg>
  );
};

export default Plus;
