import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

const CloseSVG: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = isLightMode ? COLORS.BLACK : COLORS.WHITE;

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15006 26.0383C9.42672 26.3157 9.79057 26.4542 10.1532 26.4542C10.5161 26.4542 10.8798 26.3157 11.1564 26.0383L17.6782 19.5163L24.2 26.0383C24.4769 26.3157 24.8405 26.4542 25.2032 26.4542C25.5657 26.4542 25.9293 26.3157 26.2064 26.0383C26.7605 25.4842 26.7605 24.586 26.2064 24.0319L19.6842 17.5101L25.8716 11.3227C26.426 10.7688 26.426 9.87023 25.8716 9.31631C25.3177 8.76201 24.4196 8.76201 23.8653 9.31631L17.678 15.5036L11.491 9.31612C10.9368 8.76181 10.0385 8.76181 9.48478 9.31612C8.93047 9.87004 8.93047 10.7686 9.48478 11.3225L15.672 17.5099L9.15044 24.0317C8.59575 24.5856 8.59575 25.4838 9.15006 26.0383Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseSVG;
