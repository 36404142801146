import React from 'react';
import { useParams } from 'react-router-dom';

import useGetAlbum from 'hooks/api/useGetAlbum';
import useGetImages from 'hooks/api/useGetImages';

import AlbumView from 'components/common/AlbumView';

export type Params = {
  key: string;
};

const Album: React.FunctionComponent = () => {
  const { key } = useParams<Params>();

  const { data, isLoading } = useGetAlbum({ key, options: { staleTime: 0 } });

  const isGetImagesEnabled = !!data?.key;

  const {
    data: images,
    hasNextPage,
    fetchNextPage,
  } = useGetImages({
    options: {
      enabled: isGetImagesEnabled,
      staleTime: 0,
    },
    key,
  });

  return (
    <AlbumView
      album={data}
      images={images}
      isLoading={isLoading}
      infiniteQueryOptions={{ hasNextPage: !!hasNextPage, fetchNextPage }}
    />
  );
};

export default Album;
