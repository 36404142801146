import React from 'react';
import { useParams } from 'react-router-dom';

import history from 'helpers/history';

import routes from 'constants/routes';

import useGetAlbum from 'hooks/api/useGetAlbum';
import useGetImages from 'hooks/api/useGetImages';

import AlbumView from 'components/common/AlbumView';

interface IPublicParams {
  hash: string;
}

const AlbumPublic: React.FunctionComponent = () => {
  const { hash } = useParams<IPublicParams>();

  const { data: album, isLoading } = useGetAlbum({
    key: hash,
    isPublic: true,
    options: {
      onError: () => {
        history.push(routes.MAIN);
      },
    },
  });

  const {
    data: images,
    hasNextPage,
    fetchNextPage,
  } = useGetImages({
    key: hash,
    isPublic: true,
  });

  const data = { ...album, key: hash };

  return (
    <AlbumView
      isPublic
      album={data}
      isLoading={isLoading}
      images={images}
      infiniteQueryOptions={{ hasNextPage: !!hasNextPage, fetchNextPage }}
    />
  );
};

export default AlbumPublic;
