import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

const EditAlbumSVG: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const circleColor = isLightMode ? COLORS.WHITE : COLORS.BLACK;
  const pointsColor = isLightMode ? COLORS.BLACK : COLORS.WHITE;

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="17.5" cy="17.5" r="17.5" fill={circleColor} />
      <g clipPath="url(#clip0)">
        <path
          d="M17.5 20.375C19.0878 20.375 20.375 19.0878 20.375 17.5C20.375 15.9122 19.0878 14.625 17.5 14.625C15.9122 14.625 14.625 15.9122 14.625 17.5C14.625 19.0878 15.9122 20.375 17.5 20.375Z"
          fill={pointsColor}
        />
        <path
          d="M8.875 20.375C10.4628 20.375 11.75 19.0878 11.75 17.5C11.75 15.9122 10.4628 14.625 8.875 14.625C7.28718 14.625 6 15.9122 6 17.5C6 19.0878 7.28718 20.375 8.875 20.375Z"
          fill={pointsColor}
        />
        <path
          d="M26.125 20.375C27.7128 20.375 29 19.0878 29 17.5C29 15.9122 27.7128 14.625 26.125 14.625C24.5372 14.625 23.25 15.9122 23.25 17.5C23.25 19.0878 24.5372 20.375 26.125 20.375Z"
          fill={pointsColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="23"
            height="23"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditAlbumSVG;
