import { useRef, useEffect } from 'react';

const fn = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

export default (): void => {
  const callback = useRef(fn);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    window.addEventListener('beforeunload', callback.current);

    return () => window.removeEventListener('beforeunload', callback.current);
  }, []);
};
