import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

const ArrowDownSVG: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const color = isLightMode ? COLORS.BLACK : COLORS.WHITE;

  return (
    <svg
      width="35"
      height="60"
      viewBox="0 0 35 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M17.5001 18.9706C17.8764 18.9706 18.2527 18.8269 18.5397 18.5401L27.5692 9.51047C28.1436 8.93607 28.1436 8.00479 27.5692 7.43062C26.995 6.85646 26.0639 6.85646 25.4895 7.43062L17.5001 15.4205L9.51057 7.4309C8.93617 6.85674 8.00517 6.85674 7.43105 7.4309C6.85638 8.00507 6.85638 8.93635 7.43105 9.51075L16.4604 18.5404C16.7475 18.8272 17.1238 18.9706 17.5001 18.9706Z"
        fill={color}
      />
      <path
        opacity="0.2"
        d="M17.5001 35.9706C17.8764 35.9706 18.2527 35.8269 18.5397 35.5401L27.5692 26.5105C28.1436 25.9361 28.1436 25.0048 27.5692 24.4306C26.995 23.8565 26.0639 23.8565 25.4895 24.4306L17.5001 32.4205L9.51057 24.4309C8.93617 23.8567 8.00517 23.8567 7.43105 24.4309C6.85638 25.0051 6.85638 25.9364 7.43105 26.5107L16.4604 35.5404C16.7475 35.8272 17.1238 35.9706 17.5001 35.9706Z"
        fill={color}
      />
      <g opacity="0.1">
        <path
          d="M17.5001 52.9706C17.8764 52.9706 18.2527 52.8269 18.5397 52.5401L27.5692 43.5105C28.1436 42.9361 28.1436 42.0048 27.5692 41.4306C26.995 40.8565 26.0639 40.8565 25.4895 41.4306L17.5001 49.4205L9.51057 41.4309C8.93617 40.8567 8.00517 40.8567 7.43105 41.4309C6.85638 42.0051 6.85638 42.9364 7.43105 43.5107L16.4604 52.5404C16.7475 52.8272 17.1238 52.9706 17.5001 52.9706Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ArrowDownSVG;
