import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import useScrollToTop from 'hooks/useScrollToTop';
import SignUp from 'pages/SignUp';
import SignIn from 'pages/SignIn';
import ResetPassword from 'pages/ResetPassword';
import Invite from 'pages/Invite';

import routes from 'constants/routes';

const PublicRoutes: React.FunctionComponent = () => {
  useScrollToTop();

  return (
    <Switch>
      <Route path={routes.SIGN_IN} component={SignIn} />
      <Route path={routes.SIGN_UP} component={SignUp} />
      <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
      <Route path={`${routes.INVITE}/:hash`} component={Invite} />
      <Redirect to={routes.SIGN_IN} />
    </Switch>
  );
};

export default PublicRoutes;
