import {
  IModalParameters,
  IUISettings,
  IUser,
  IAlbumResponse,
} from 'types/models';

export enum ACTION_TYPES {
  SET_USER = 'SET_USER',
  SET_THEME = 'SET_THEME',
  SET_MODAL_PARAMETERS = 'SET_MODAL_PARAMETERS',
  SET_UI_SETTINGS = 'SET_UI_SETTINGS',
  SET_ALBUM_ACTIVITY = 'SET_ALBUM_ACTIVITY',
  EDIT_ALBUM_SUCCESS = 'EDIT_COVER_SUCCESS',
  SET_USER_TOKEN = 'SET_USER_TOKEN',
  SET_USER_INVITE_REDIRECT = 'SET_USER_INVITE_REDIRECT',
}

export interface ISetUser {
  type: ACTION_TYPES.SET_USER;
  payload: IUser;
}

export interface ISetTheme {
  type: ACTION_TYPES.SET_THEME;
}

export interface ISetModalParameters {
  type: ACTION_TYPES.SET_MODAL_PARAMETERS;
  payload: IModalParameters;
}

export interface ISetUISettings {
  type: ACTION_TYPES.SET_UI_SETTINGS;
  payload: IUISettings;
}

export interface IEditAlbumSuccess {
  type: ACTION_TYPES.EDIT_ALBUM_SUCCESS;
  payload: IAlbumResponse;
}

export interface ISetUserToken {
  type: ACTION_TYPES.SET_USER_TOKEN;
  payload: string;
}

export interface ISetUserInviteRedirect {
  type: ACTION_TYPES.SET_USER_INVITE_REDIRECT;
  payload: string;
}

export type TActions =
  | ISetModalParameters
  | ISetUser
  | ISetTheme
  | ISetUISettings
  | IEditAlbumSuccess
  | ISetUserToken
  | ISetUserInviteRedirect;
