import React, { useContext } from 'react';

import { AppContext } from 'context/AppProvider';
import { COLORS } from 'constants/constants';

interface IPhotosCounterSVG {
  value: number;
}

const wrapperStyles = {
  position: 'relative',
  display: 'inline-block',
} as React.CSSProperties;

const PhotosCounterSVG: React.FunctionComponent<IPhotosCounterSVG> = ({
  value,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const imgColor = isLightMode ? COLORS.BLACK : COLORS.WHITE;
  const textColor = isLightMode ? COLORS.WHITE : COLORS.BLACK;

  const textStyles = {
    position: 'absolute',
    color: textColor,
    left: '5px',
    top: '5px',
    fontWeight: 700,
    width: '12px',
    height: '14px',
    textAlign: 'center',
    fontSize: '14px',
  } as React.CSSProperties;

  return (
    <div style={wrapperStyles}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 8H29C30.6569 8 32 9.34313 32 11V29C32 30.6569 30.6569 32 29 32H11C9.34313 32 8 30.6569 8 29V28H7C5.34312 28 4 26.6569 4 25V24H3C1.34312 24 0 22.6569 0 21V3C0 1.34312 1.34312 0 3 0H21C22.6569 0 24 1.34312 24 3V4H25C26.6569 4 28 5.34312 28 7V8ZM6 25C6 25.5523 6.44769 26 7 26H25C25.5523 26 26 25.5523 26 25V7C26 6.44769 25.5523 6 25 6H24V21C24 22.6569 22.6569 24 21 24H6V25ZM29 30C29.5523 30 30 29.5523 30 29V11C30 10.4477 29.5523 10 29 10H28V25C28 26.6569 26.6569 28 25 28H10V29C10 29.5523 10.4477 30 11 30H29Z"
          fill={imgColor}
        />
      </svg>
      <span style={textStyles}>{value}</span>
    </div>
  );
};

export default PhotosCounterSVG;
