import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { onAuthStateChanged } from 'services/firebaseApi/auth';
import { saveToStorage, getFromStorage } from 'services/localStorage';

import reducer from 'context/modules/main/reducer';
import uploadAlbumReducer from 'context/modules/uploadAlbum/reducer';
import { ACTION_TYPES, TActions } from 'context/modules/main/actions';
import { TUploadAlbumActions } from 'context/modules/uploadAlbum/actions';

import {
  LOCAL_STORAGE_KEYS,
  CHECK_STATUS_CONDITIONS,
} from 'constants/constants';
import routes from 'constants/routes';

import history from 'helpers/history';

import ChoosePhotosModal from 'components/ChoosePhotosModal';
import LoadScreen from 'components/LoadScreen';

import {
  IUISettings,
  IUser,
  IModalParameters,
  IAlbumResponse,
} from 'types/models';
import ModalWrapper from 'components/common/ModalWrapper';

export interface IState {
  user: IUser;
  ui: IUISettings;
  albums: IAlbumResponse[];
  modalParameters: IModalParameters;
}

export interface INewAlbumState {
  data?: IAlbumResponse;
  isCheckStatusEnabled?: boolean | CHECK_STATUS_CONDITIONS;
  loading: boolean;
  error: string;
}

type AppProviderProps = {
  children: React.ReactNode;
};

const initialState = {
  user: {} as IUser,
  ui: {
    isLightMode: true,
    isAuthorized: false,
  } as IUISettings,
  albums: [],
  modalParameters: {
    visible: false,
    isChoosePhotos: false,
  },
};

const uploadAlbumInitialState = {
  loading: false,
  error: '',
};

export const AppContext = createContext<{
  state: IState;
  dispatch: React.Dispatch<TActions>;
  uploadAlbum: INewAlbumState;
  dispatchUploadAlbum: React.Dispatch<TUploadAlbumActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
  uploadAlbum: uploadAlbumInitialState,
  dispatchUploadAlbum: () => undefined,
});

const AppProvider: React.FunctionComponent<AppProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [uploadAlbum, dispatchUploadAlbum] = useReducer(
    uploadAlbumReducer,
    uploadAlbumInitialState
  );
  const [isAuthChecked, setAuthChecked] = useState(false);
  const { pathname, search } = useLocation();
  const isInviteUrl = useRouteMatch(routes.INVITE);
  const isPublicUrl = useRouteMatch(routes.PUBLIC_VIEW);
  const modalContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const {
    ui: { isLightMode },
    user: { id },
    modalParameters: { visible, isChoosePhotos, content, wrapperStyles },
  } = state;

  useEffect(() => {
    const uiSettings = getFromStorage(LOCAL_STORAGE_KEYS.UI) as IUISettings;
    onAuthStateChanged(dispatch, setAuthChecked);

    if (uiSettings) {
      dispatch({
        type: ACTION_TYPES.SET_UI_SETTINGS,
        payload: { ...state.ui, ...uiSettings },
      });
    }

    const isRedirect = uiSettings?.lastVisitURL && !isInviteUrl && !isPublicUrl;

    if (isRedirect) {
      history.push(uiSettings.lastVisitURL);
    }
  }, []);

  useEffect(() => {
    const isAuthorized = !!id;
    const lastVisitURL = `${pathname}${search || ''}`;
    const storageUi = getFromStorage(LOCAL_STORAGE_KEYS.UI) as IUISettings;

    const uiSettings = {
      ...storageUi,
      isAuthorized,
      lastVisitURL,
      isLightMode,
    } as IUISettings;

    saveToStorage(LOCAL_STORAGE_KEYS.UI, uiSettings);
  }, [id, pathname, search, isLightMode]);

  const value = {
    state,
    dispatch,
    uploadAlbum,
    dispatchUploadAlbum,
  };

  const isModalVisible = visible && modalContainerRef;

  return (
    <AppContext.Provider value={value}>
      <div ref={modalContainerRef} />

      {isAuthChecked ? children : <LoadScreen />}

      {isModalVisible &&
        ReactDOM.createPortal(
          <ModalWrapper classNames={wrapperStyles}>
            {isChoosePhotos ? <ChoosePhotosModal /> : <>{content}</>}
          </ModalWrapper>,
          modalContainerRef.current
        )}
    </AppContext.Provider>
  );
};

export default AppProvider;
