import React, { useContext, useMemo } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';
import useScrollY from 'hooks/useScrollY';
import Container from 'components/common/Container';

import styles from './index.module.scss';

interface IHeader {
  leftButton: JSX.Element;
  heading: string;
  rightButton?: JSX.Element;
  fixedOnScroll?: number;
  isShadowed?: boolean;
  isHeadingUppercase?: boolean;
  headingClasses?: string;
}

const Header: React.FunctionComponent<IHeader> = ({
  leftButton,
  heading,
  rightButton,
  fixedOnScroll,
  isShadowed,
  isHeadingUppercase,
  headingClasses,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);
  const scrollPosition = useScrollY();

  const isHeaderFixed = fixedOnScroll && scrollPosition > fixedOnScroll;
  const isTitleTransparent = fixedOnScroll && fixedOnScroll > 1;

  const moduleClasses = classNames.bind(styles);

  const headerClasses = useMemo(
    () =>
      moduleClasses('header', {
        'header-fixed': isHeaderFixed,
        'header-fixed--dark': isHeaderFixed && !isLightMode,
        'header-transparent': !fixedOnScroll,
        'header-shadowed': isShadowed,
        'header-shadowed--dark': isShadowed && !isLightMode,
        'header-two-children': !rightButton,
      }),
    [isHeaderFixed, isLightMode]
  );

  const headingStyles = useMemo(
    () =>
      moduleClasses('heading', {
        'heading-dark': !isLightMode,
        'heading-transparent': isTitleTransparent && !isHeaderFixed,
        'heading-uppercase': isHeadingUppercase,
      }),
    [isHeaderFixed, isLightMode]
  );

  const containerClasses = moduleClasses('container', {
    'container-two-children': !rightButton,
  });

  return (
    <header className={headerClasses}>
      <Container className={containerClasses}>
        <div>{leftButton}</div>
        <h1 className={`${headingStyles} ${headingClasses}`}>{heading}</h1>
        <div>{rightButton}</div>
      </Container>
    </header>
  );
};

export default Header;
