import { useContext } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { getAlbum } from 'services/api/albums';
import { IAlbumResponse } from 'types/models';

interface IGetAlbum {
  data?: IAlbumResponse;
  isLoading?: boolean;
}

interface IGetAlbumProps {
  key: string;
  options?: UseQueryOptions<IAlbumResponse>;
  isPublic?: boolean;
}

export default ({ key, options, isPublic }: IGetAlbumProps): IGetAlbum => {
  const {
    state: {
      user: { token },
    },
    dispatch,
  } = useContext(AppContext);

  const queryKey = isPublic ? `public ${key}` : key;

  const { data, isLoading } = useQuery(
    queryKey,
    () => getAlbum(dispatch, key, token, isPublic),
    { staleTime: Infinity, ...options }
  );

  return { data, isLoading };
};
