import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';

import { UPLOADED_PHOTOS_MAX_LENGTH } from 'constants/constants';

import plusSVG from 'assets/icons/plus';
import PlusSmall from 'assets/icons/plus-small';

import styles from './index.module.scss';

interface IAddPhoto {
  loading?: boolean;
  disabledActions?: boolean;
  isFixed?: boolean;
  isSelectCoverMode?: boolean;
  save?: () => void;
  isLoading?: boolean;
}

const AddPhotos: React.FunctionComponent<IAddPhoto> = ({
  loading,
  disabledActions,
  isFixed,
  isSelectCoverMode,
  save,
  isLoading,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
    uploadAlbum: { isCheckStatusEnabled, data },
    dispatch,
  } = useContext(AppContext);

  const openModal = () => {
    dispatch({
      type: ACTION_TYPES.SET_MODAL_PARAMETERS,
      payload: { visible: true, isChoosePhotos: true },
    });
  };

  const moduleClasses = classNames.bind(styles);
  const titleClasses = moduleClasses('title', {
    'title-dark': !isLightMode,
  });
  const addClasses = moduleClasses('add', {
    'add-dark': !isLightMode,
  });

  const saveButtonText = isSelectCoverMode ? 'Set cover' : 'Save';
  const isAddButtonDisabled =
    data?.size === UPLOADED_PHOTOS_MAX_LENGTH || disabledActions;
  const isSaveButtonDisabled = isLoading || disabledActions;
  const isSaveButtonVisible = !isCheckStatusEnabled && isSelectCoverMode;
  const isAddButtonVisible = !isCheckStatusEnabled && !isSelectCoverMode;

  return isFixed ? (
    <div className={addClasses}>
      {isCheckStatusEnabled && <Spinner width={4} size={37} />}

      {isSaveButtonVisible && (
        <Button
          classes={`${styles.button} ${styles.save}`}
          title={saveButtonText}
          onClick={save}
          disabled={isSaveButtonDisabled}
        />
      )}

      {isAddButtonVisible && (
        <button
          type="button"
          title="Add photos"
          className={styles.button}
          onClick={openModal}
          disabled={isAddButtonDisabled}
        >
          <PlusSmall />
          <span>Add photos</span>
        </button>
      )}
    </div>
  ) : (
    <div className={styles.container}>
      <h2 className={titleClasses}>Uploaded photos</h2>
      {loading && (
        <div className={styles.loading}>
          <Spinner size={50} />
        </div>
      )}

      {!loading && (
        <Button
          title="add photos"
          isMedium
          IconComponent={plusSVG}
          onClick={openModal}
          disabled={disabledActions}
        />
      )}
    </div>
  );
};

export default AddPhotos;
