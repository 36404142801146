import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';

import styles from './index.module.scss';

interface IError {
  message: string;
  className?: string;
  disableModeSwitch?: boolean;
}

const Error: React.FunctionComponent<IError> = ({
  message,
  className,
  disableModeSwitch,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const moduleClasses = classNames.bind(styles);
  const errorClasses = moduleClasses('error', {
    'error-dark': !disableModeSwitch && !isLightMode,
  });

  return <p className={`${className} ${errorClasses}`}>{message}</p>;
};

export default Error;
