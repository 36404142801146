import * as Yup from 'yup';

export const validationSchemaSignUp = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string()
      .email('E-mail is not valid!')
      .required('E-mail is required!'),
    password: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Password is required!'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords does not match'),
    username: Yup.string()
      .min(2, 'Username has to be at least 2 chars')
      .matches(/^[a-zA-Z]+$/, 'Username must contain only letters!')
      .required('Username is required!'),
  })
);

export const validationSchemaSignIn = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string()
      .email('E-mail is not valid!')
      .required('E-mail is required!'),
    password: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Password is required!'),
  })
);

export const validationSchemaResetPassword = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string()
      .email('E-mail is not valid!')
      .required('E-mail is required!'),
  })
);

export const validationSchemaChangeProfile = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string()
      .email('E-mail is not valid!')
      .required('E-mail is required!'),
    password: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Password is required!'),
    username: Yup.string()
      .min(2, 'At least 2 chars')
      .matches(/^[a-zA-Z]+$/, 'Username must contain only letters!')
      .required('Username is required!'),
  })
);

export const validationSchemaChangePassword = Yup.lazy(() =>
  Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Password is required!'),
    newPassword: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Password is required!'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords does not match'),
  })
);
