import React from 'react';
import classNames from 'classnames/bind';

import { ICoverPicture, TNewPhoto } from 'types/models';
import UploadedPhoto from 'components/common/UploadedPhoto';

import styles from './index.module.scss';

interface IEditPhotoList {
  photos?: TNewPhoto[];
  isSmall?: boolean;
  resetError?: () => void;
  loading?: boolean;
  setNewCover?: (photo: TNewPhoto) => void;
  mainPhoto?: ICoverPicture;
  isProcessed?: boolean;
  disabledActions?: boolean;
}

const EditPhotoList: React.FunctionComponent<IEditPhotoList> = ({
  photos,
  isSmall,
  resetError,
  loading,
  setNewCover,
  mainPhoto,
  isProcessed,
  disabledActions,
}) => {
  const moduleClasses = classNames.bind(styles);
  const listClasses = moduleClasses('list', {
    'list-small': isSmall,
    'list-processed': isProcessed,
  });

  return (
    <ul className={listClasses}>
      {!!photos &&
        photos.map((photo) => (
          <UploadedPhoto
            key={photo.key}
            data={photo}
            isSmall={isSmall}
            resetError={resetError}
            loading={loading}
            setNewCover={setNewCover}
            mainPhotoKey={mainPhoto?.key}
            disabledActions={disabledActions}
          />
        ))}
    </ul>
  );
};

export default EditPhotoList;
