import { useContext, useEffect } from 'react';

import { AppContext } from 'context/AppProvider';

const ErrorWatcher: React.FunctionComponent = () => {
  const {
    uploadAlbum: { error },
  } = useContext(AppContext);

  useEffect(() => {
    if (error.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [error]);

  return null;
};

export default ErrorWatcher;
