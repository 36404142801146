/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { AppContext } from 'context/AppProvider';

import defaultAvatar from 'assets/images/default-avatar.svg';

import styles from './index.module.scss';

interface IAvatarField {
  register: UseFormRegisterReturn;
  disableChanges?: boolean;
}

const AvatarField: React.FunctionComponent<IAvatarField> = ({
  register,
  disableChanges,
}) => {
  const {
    state: {
      user: { photoURL },
    },
  } = useContext(AppContext);
  const [userAvatar, setUserAvatar] =
    useState<string | ArrayBuffer | null | undefined>(photoURL);

  const fileInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    const img = files && files[0];

    if (files && img) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const readImg = e.target?.result;
        setUserAvatar(readImg);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const currentImage =
    typeof userAvatar === 'string' ? userAvatar : defaultAvatar;

  return (
    <div className={styles.avatar}>
      <div className={styles.avatar__photo}>
        <img src={currentImage} alt="avatar" referrerPolicy="no-referrer" />
      </div>
      <input
        type="file"
        className={styles.avatar__input}
        accept=".png, .jpg, .jpeg"
        {...register}
        onChange={fileInputHandler}
        disabled={disableChanges}
      />
    </div>
  );
};

export default AvatarField;
