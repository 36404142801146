import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AppContext } from 'context/AppProvider';
import { TNewPhoto, IInfiniteQueryOptions } from 'types/models';
import Container from 'components/common/Container';
import Error from 'components/common/Error';
import AddPhotos from 'components/common/AddPhotos';
import EditPhotoList from 'components/common/EditPhotosList';
import Spinner from 'components/common/Spinner';
import AlbumLengthHandler from 'components/common/AlbumLengthHandler';

import styles from './index.module.scss';

interface IEditAlbumPhotos {
  photos?: TNewPhoto[];
  error?: string;
  loading?: boolean;
  resetError?: () => void;
  albumLength: number;
  setAlbumLength: (value: number) => void;
  infiniteQueryOptions?: IInfiniteQueryOptions;
  albumSize?: number;
  disabledActions: boolean;
}

const EditAlbumPhotos: React.FunctionComponent<IEditAlbumPhotos> = ({
  photos,
  loading,
  error,
  resetError,
  albumLength,
  setAlbumLength,
  infiniteQueryOptions,
  albumSize,
  disabledActions,
}) => {
  const {
    uploadAlbum: { isCheckStatusEnabled },
  } = useContext(AppContext);

  const { hasNextPage, fetchNextPage } = infiniteQueryOptions || {};

  const nextPage = () => {
    if (fetchNextPage) {
      fetchNextPage();
    }
  };

  const isInfiniteQueryAllowed = isCheckStatusEnabled ? false : !!hasNextPage;

  return (
    <Container className={styles.container}>
      <main>
        <AlbumLengthHandler
          albumLength={albumLength}
          setAlbumLength={setAlbumLength}
          classes={styles.length}
          maxValue={albumSize}
        />

        <div className={styles.error}>
          <Error message={error || ''} />
        </div>

        <AddPhotos loading={loading} disabledActions={disabledActions} />

        {photos && (
          <>
            {infiniteQueryOptions && (
              <InfiniteScroll
                hasMore={isInfiniteQueryAllowed}
                next={nextPage}
                style={{ overflowY: 'hidden' }}
                dataLength={Number(photos.length)}
                loader={
                  <div className={styles.spinner}>
                    <Spinner size={80} width={8} />
                  </div>
                }
              >
                <EditPhotoList
                  photos={photos}
                  isSmall
                  resetError={resetError}
                  loading={loading}
                  disabledActions={disabledActions}
                />
              </InfiniteScroll>
            )}
          </>
        )}
      </main>
    </Container>
  );
};

export default EditAlbumPhotos;
