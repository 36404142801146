import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import useCheckDraftAlbum from 'hooks/api/useCheckDraftAlbum';

import { AppContext } from 'context/AppProvider';

import Container from 'components/common/Container';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import MainContainer from 'components/common/MainContainer';
import Spinner from 'components/common/Spinner';

import plusSVG from 'assets/icons/plus';

import styles from './index.module.scss';

interface IAlbumsEmpty {
  profileLink: JSX.Element;
  themeSwitcher: JSX.Element;
  isLoading: boolean;
}

const AlbumsEmpty: React.FunctionComponent<IAlbumsEmpty> = ({
  profileLink,
  themeSwitcher,
  isLoading,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const { setEnabled: setCheckDraftEnabled } = useCheckDraftAlbum(true);

  const openModal = () => {
    setCheckDraftEnabled(true);
  };

  const moduleClasses = classNames.bind(styles);
  const wrapperClasses = moduleClasses('wrapper', {
    'wrapper-dark': !isLightMode,
  });
  const titleClasses = moduleClasses('title', {
    'title-dark': !isLightMode,
  });
  const mainClasses = moduleClasses('main', {
    'main-loading': isLoading,
  });

  return (
    <MainContainer style={wrapperClasses}>
      <Header
        leftButton={themeSwitcher}
        heading="Albums"
        rightButton={profileLink}
        isHeadingUppercase
      />

      <main className={mainClasses}>
        <Container className={styles.container}>
          {isLoading ? (
            <Spinner size={100} width={10} />
          ) : (
            <>
              <h2 className={titleClasses}>
                You don’t have albums yet. Start creating your first album.
              </h2>
              <Button
                title="create album"
                IconComponent={plusSVG}
                onClick={openModal}
              />
            </>
          )}
        </Container>
      </main>
    </MainContainer>
  );
};

export default AlbumsEmpty;
