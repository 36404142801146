import { useContext } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { getAlbumShareLink } from 'services/api/albums';
import { AppContext } from 'context/AppProvider';

export default (
  albumKey?: string,
  options?: UseQueryOptions<string>
): string | undefined => {
  const {
    state: {
      user: { token },
    },
    dispatch,
  } = useContext(AppContext);

  const { data } = useQuery(
    `${albumKey} share-link`,
    () => getAlbumShareLink(dispatch, token, albumKey),
    {
      staleTime: Infinity,
      ...options,
    }
  );

  return data;
};
