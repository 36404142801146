import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getAlbums } from 'services/api/albums';

import routes from 'constants/routes';
import { QUERY_KEYS } from 'constants/constants';

import { AppContext } from 'context/AppProvider';
import { ACTION_TYPES } from 'context/modules/main/actions';

import AlbumsEmpty from 'components/AlbumsEmpty';
import AlbumsFull from 'components/AlbumsFull';
import Switcher from 'components/common/Switcher';

import ProfileSVG from 'assets/icons/profile';

import styles from './index.module.scss';

const profileLink = (
  <Link to={routes.PROFILE} className={styles.link}>
    <ProfileSVG isDark width="32" height="32" />
  </Link>
);

const Albums: React.FunctionComponent = () => {
  const {
    state: {
      ui: { isLightMode },
      user: { token },
    },
    dispatch,
  } = useContext(AppContext);

  const { data, isLoading } = useQuery(
    QUERY_KEYS.ALBUMS_LIST,
    () => getAlbums(dispatch, token),
    { staleTime: Infinity }
  );

  const changeModeHandler = (): void => {
    dispatch({ type: ACTION_TYPES.SET_THEME });
  };

  const themeSwitcher = (
    <Switcher onChange={changeModeHandler} checked={!isLightMode} />
  );

  const isAlbumsLength = data && !!data.length;
  const AlbumComponent = isAlbumsLength ? AlbumsFull : AlbumsEmpty;

  return (
    <AlbumComponent
      profileLink={profileLink}
      themeSwitcher={themeSwitcher}
      albums={data}
      isLoading={isLoading}
    />
  );
};

export default Albums;
