import React from 'react';

import styles from './index.module.scss';

interface IContainer {
  children: React.ReactNode;
  className?: string;
}

const Container: React.FunctionComponent<IContainer> = ({
  children,
  className,
}) => <div className={`${styles.container} ${className}`}>{children}</div>;

export default Container;
