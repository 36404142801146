import axios from 'axios';

import { authorizedPostRequest, authorizedPutRequest } from 'helpers/axios';
import getFileExtension from 'helpers/getFileExtension';
import { TActions } from 'context/modules/main/actions';
import { TUpdateEmailResponse } from 'types/models';

const USER_API_BASE_PATH = '/users/';

export const uploadProfilePicture = async (
  dispatch: React.Dispatch<TActions>,
  picture: File,
  token: string
): Promise<string> => {
  try {
    const fileExtension = getFileExtension(picture.type);

    const body = {
      profile_picture_extension: fileExtension,
    };

    const data = await authorizedPostRequest<{
      // eslint-disable-next-line camelcase
      upload_link: string;
      // eslint-disable-next-line camelcase
      remote_url: string;
    }>({
      token,
      dispatch,
      path: `${USER_API_BASE_PATH}profile-picture/`,
      body,
    });

    const { upload_link: link, remote_url: url } = data;

    await axios.put(link, picture, {
      headers: { 'Content-Type': picture.type },
    });

    return url;
  } catch (error) {
    throw Error(error.message);
  }
};

export const updateProfileEmail = async (
  dispatch: React.Dispatch<TActions>,
  newEmail: string,
  token: string
): Promise<TUpdateEmailResponse> => {
  try {
    const data = await authorizedPutRequest<TUpdateEmailResponse>({
      token,
      dispatch,
      path: USER_API_BASE_PATH,
      body: {
        email: newEmail,
      },
    });

    return data;
  } catch (error) {
    throw Error(error.message);
  }
};
