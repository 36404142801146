import { useContext } from 'react';
import { useMutation, UseMutateFunction, useQueryClient } from 'react-query';

import { AppContext } from 'context/AppProvider';
import { IAlbumResponse, TError } from 'types/models';
import { confirmCreation } from 'services/api/albums';
import { UPLOAD_ALBUM_ACTION_TYPES } from 'context/modules/uploadAlbum/actions';
import history from 'helpers/history';
import routes from 'constants/routes';
import { QUERY_KEYS } from 'constants/constants';

interface IConfirmAlbum {
  mutate: UseMutateFunction<IAlbumResponse, TError>;
  isLoading: boolean;
}

export default (): IConfirmAlbum => {
  const {
    state: {
      user: { token },
    },
    dispatch,
    uploadAlbum: { data },
    dispatchUploadAlbum,
  } = useContext(AppContext);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<IAlbumResponse, TError>(
    () => confirmCreation(dispatch, data, token),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(QUERY_KEYS.ALBUMS_LIST);

        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_SUCCESS,
          payload: {
            ...response,
          },
        });
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_PHOTOS,
          payload: [],
        });

        history.push(`${routes.CREATE_ALBUM_SETTINGS}/${response.key}`);
      },
      onError: (err) => {
        dispatchUploadAlbum({
          type: UPLOAD_ALBUM_ACTION_TYPES.SET_UPLOAD_ALBUM_ERROR,
          payload: err.message,
        });
      },
    }
  );

  return { mutate, isLoading };
};
