import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { AppContext } from 'context/AppProvider';

import styles from './index.module.scss';

interface IButton {
  title: string;
  isSubmit?: boolean;
  onClick?: () => void;
  icon?: string;
  IconComponent?: React.FunctionComponent;
  disableModeSwitch?: boolean;
  isSmall?: boolean;
  isBig?: boolean;
  isMedium?: boolean;
  isShadowed?: boolean;
  isWhiteBig?: boolean;
  disabled?: boolean;
  classes?: string;
  id?: string;
  additionalClasses?: string;
}

const Button: React.FunctionComponent<IButton> = ({
  title,
  isSubmit,
  onClick,
  icon,
  IconComponent,
  disableModeSwitch,
  isSmall,
  isBig,
  isMedium,
  isShadowed,
  disabled,
  classes,
  id,
  additionalClasses,
}) => {
  const {
    state: {
      ui: { isLightMode },
    },
  } = useContext(AppContext);

  const moduleClasses = classNames.bind(styles);
  const buttonClasses = moduleClasses('button', {
    'button-icon': icon,
    'button-icon-title': IconComponent,
    'button-icon-title-dark': IconComponent && !isLightMode,
    'button-icon-title--medium': IconComponent && isMedium,
    'button-light': isLightMode && !disableModeSwitch,
    'button-dark': !isLightMode && !disableModeSwitch,
    'button-small': isSmall,
    'button-medium': isMedium && !IconComponent,
    'button-big': isBig,
    'button-shadowed': isShadowed,
    'button-big--dark': isBig && !disableModeSwitch && !isLightMode,
  });

  const isTitleVisible = title && !icon;
  const buttonStyles = classes || `${buttonClasses} ${additionalClasses || ''}`;

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      className={buttonStyles}
      disabled={disabled}
      id={id}
    >
      {IconComponent && <IconComponent />}
      {isTitleVisible && title}
      {icon && <img src={icon} alt={title} />}
    </button>
  );
};

export default Button;
